import {
  AddNewCartFeasibilityInfo,
  PageNotFound
} from '@/components/atomic/atoms';
import { CartList } from '@/components/atomic/molecules';
import { CART_LIST_TYPE, USER_EVENT_STAGE } from '@/config/common';
import {
  filterActiveCart,
  filterArchivedCart,
  filterOrderedCart,
  transformEventInfoFields
} from '@/services/userEventCartList.service';

const EventCartListsOrganism = ({
  setEditCartSummaryModal,
  setLoading,
  setShowToast,
  setUserEventCartDetails,
  userEventCartDetails
}) => {
  const { userEventInformation: userEvent, userEventCartList } =
    userEventCartDetails;
  const {
    user: userInfo = {},
    userEventDetails: [{ userEventId } = {}] = [],
    stage: userEventStage
  } = userEvent;
  const cartInfoFields = transformEventInfoFields({
    userEvent
  });

  const isAnyDummyOrNullValueInFields = cartInfoFields.some(
    ({ isEmptyOrDummyValue }) => isEmptyOrDummyValue
  );
  const isUserEventInCreatedStage =
    userEventStage === USER_EVENT_STAGE.EVENT_CREATED.value;
  const showFeasibilityInfo =
    isUserEventInCreatedStage && isAnyDummyOrNullValueInFields;

  if (userEventCartList.length === 0) {
    return (
      <div>
        {showFeasibilityInfo ? (
          <AddNewCartFeasibilityInfo {...{ cartInfoFields }} />
        ) : (
          <div className='bg-white h-[80vh] w-full flex justify-center'>
            <PageNotFound
              {...{
                alt: 'no event cart found',
                className: 'py-20',
                description: 'Add New Cart',
                iconHeight: 345,
                iconWidth: 394,
                notFoundImage: 'no-cart-found.svg',
                title: ''
              }}
            />
          </div>
        )}
      </div>
    );
  }

  const cartCardListCommonProps = {
    noCartFoundTitle: 'No Cart Found',
    setEditCartSummaryModal,
    setLoading,
    setShowToast,
    setUserEventCartDetails,
    userEventId,
    userInfo
  };

  const cartCardList = [
    {
      cartListType: CART_LIST_TYPE.EVENT,
      showCartListHeader: false,
      userEventCartList: userEventCartList.filter(filterActiveCart)
    },
    {
      cartListType: CART_LIST_TYPE.ORDER,
      showControlToCollapseList: true,
      showCreateTenderAndDispatchBidsCTA: false,
      title: 'Event Order List',
      userEventCartList: userEventCartList.filter(filterOrderedCart)
    },
    {
      cartListType: CART_LIST_TYPE.ARCHIVE,
      showControlToCollapseList: true,
      title: 'Archived Carts',
      userEventCartList: userEventCartList.filter(filterArchivedCart)
    }
  ];

  return (
    <div>
      {cartCardList.map(
        (cartCardListProps, index) =>
          cartCardListProps.userEventCartList.length > 0 && (
            <CartList
              key={index}
              {...{ cartCardListCommonProps, ...cartCardListProps }}
            />
          )
      )}
    </div>
  );
};

export default EventCartListsOrganism;
