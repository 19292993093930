import { useState } from 'react';
import { useFormState } from 'react-hook-form';

import {
  NavigateCTA,
  PhoneCodeSelectFormInput
} from '@/components/atomic/atoms';
import {
  InputField,
  SearchkitSelectFormInput,
  SelectTags,
  Text,
  TextKeyValuePair
} from '@/components/atomic/nuclei';
import { TAG_OPTIONS_SELECTION_MODE } from '@/config/common';
import {
  HOST_CREDIT_ENTITY_DROPDOWN_HIT_LIMIT,
  NAVIGATION_INDEX,
  TAB_CATEGORY_ATTRIBUTE,
  TAB_CATEGORY_ENUM
} from '@/config/searchkit/navigation';
import createClient from '@/lib/haflaSearchkitClient';
import { PLANNER_PORTAL_FQDN } from '@/services/connections.service';
import { validateIfMobileNumberMatch } from '@/services/createUserEvent.service';
import { validateHostCreditEntityId } from '@/services/hostProfile.service';
import { getAuthToken } from '@/services/identity.service';
import { SEARCH_KIT_NAVIGATION_SEARCH_API_URL } from '@/services/url.service';

const searchClient = createClient({
  headers: {
    'x-access-token': getAuthToken(),
    'x-origin-domain': PLANNER_PORTAL_FQDN
  },
  url: SEARCH_KIT_NAVIGATION_SEARCH_API_URL
});

const BasicHostProfileForm = ({
  activeModalsConfig,
  existingHostProfile,
  isValidHostTypeForCreditEntity,
  onClickExit,
  onSubmitHostProfile,
  selectedHostCreditEntityName,
  setActiveModal,
  setExistingHostProfile,
  setIsValidHostTypeForCreditEntity,
  setSelectedHostCreditEntityName,
  useFormProps
}) => {
  const {
    clearErrors,
    control,
    errors,
    getValues,
    handleSubmit,
    register,
    setValue,
    trigger,
    watch
  } = useFormProps;

  const [
    isNewOrExistingMobileNumberChecked,
    setIsNewOrExistingMobileNumberChecked
  ] = useState(false);
  const [searchQuery, setSearchQuery] = useState();

  const isExistingHostProfile = Boolean(existingHostProfile?.id);

  const preventToChangeHostCreditEntity =
    isExistingHostProfile && existingHostProfile?.hostCreditEntity?.name;

  const showHostCreditEntity =
    isValidHostTypeForCreditEntity ||
    existingHostProfile?.hostCreditEntity?.name;

  const { isValid } = useFormState({
    control
  });

  return (
    <div>
      <div className='px-6 flex flex-col gap-4 md:gap-6'>
        <TextKeyValuePair
          {...{
            className: 'flex flex-col gap-2 md:gap-3',
            label: 'Let’s Get Started!',
            labelClass: 'text-[#F98696] text-lg md:text-2xl font-semibold',
            value:
              'Please provide the host details to start the event creation process.',
            valueClassName: 'text-sm md:text-base font-medium text-[#9C9CAF]'
          }}
        />
        <div className='relative'>
          <Text
            {...{
              className:
                'text-[#9C9CAF] text-sm md:text-base font-medium pb-2',
              content: 'Host Mobile Number *'
            }}
          />
          <div className='flex'>
            <PhoneCodeSelectFormInput
              {...{
                className: 'phone-code-select text-sm md:text-base w-28',
                clearErrors,
                control,
                dbName: 'codeId',
                errors,
                getValues,
                inputFieldProps: {
                  alt: 'phone number icon',
                  className:
                    'phone-code-input border border-platinum rounded-r-lg h-10 text-sm md:text-base pl-10',
                  dbName: 'phoneNumber',
                  errors,
                  iconURL: 'phone-brand-icon.svg',
                  inputGroup: 'flex flex-1',
                  placeholder: 'Enter Phone Number',
                  register: register('phoneNumber', {
                    onChange: (e) => {
                      setValue('phoneNumber', e.target.value);
                      setIsNewOrExistingMobileNumberChecked(false);
                      if (isExistingHostProfile) {
                        clearErrors();
                        setValue('customerName', null);
                        setValue('hostCreditEntityId', null);
                        setValue('hostSegmentId', null);
                        setSelectedHostCreditEntityName('');
                        setExistingHostProfile(null);
                      }
                    }
                  }),
                  type: 'number',
                  onBlur: async () => {
                    const isValidPhoneNumber = await trigger('phoneNumber');
                    const mobileNumber = `${watch('codeId')?.code}${watch(
                      'phoneNumber'
                    )}`;
                    if (isValidPhoneNumber) {
                      clearErrors();
                      validateIfMobileNumberMatch({
                        getValues,
                        mobile: mobileNumber,
                        setExistingHostProfile,
                        setIsNewOrExistingMobileNumberChecked,
                        setIsValidHostTypeForCreditEntity,
                        setSelectedHostCreditEntityName,
                        setValue,
                        trigger
                      });
                    }
                  }
                },
                register,
                selectContainerClassName: 'phone-code-container',
                setValue,
                trigger,
                width: 'w-20 md:w-20'
              }}
            />
          </div>
          {isNewOrExistingMobileNumberChecked && (
            <Text
              {...{
                className: 'text-sm mx-[120px] w-[140px] text-[#017AAD] pt-1',
                content: `${isExistingHostProfile ? 'Existing' : 'New'} Host`
              }}
            />
          )}
        </div>
        <InputField
          {...{
            alt: 'host name icon',
            className:
              'rounded-lg pl-10 !py-4 text-sm md:text-base placeholder:text-[#9C9CAF]',
            dbName: 'customerName',
            disabled: Boolean(existingHostProfile?.name),
            errors,
            iconURL: 'host-brand-v1-icon.svg',
            inputGroup: 'flex flex-col gap-2',
            label: 'Host Name *',
            labelClass: 'text-[#9C9CAF] text-sm md:text-base font-medium',
            maxLength: 64,
            placeholder: 'Enter Host Name',
            register: { ...register('customerName') }
          }}
        />
        <SelectTags
          {...{
            errorMessage: errors?.hostSegmentId?.message,
            isEditable: !existingHostProfile?.hostSegmentId,
            selectedTagOptions: watch('hostSegmentId'),
            setSelectedTagOptions: (hostSegmentId) => {
              setValue('hostSegmentId', hostSegmentId);
              const isValidHostType = validateHostCreditEntityId({
                hostSegmentId,
                hostCreditEntityId: null,
                hostTypeList: getValues('hostTypeList')
              });
              trigger('hostSegmentId');
              setIsValidHostTypeForCreditEntity(!isValidHostType);
              if (isValidHostType) {
                clearErrors('hostCreditEntityId');
                setSelectedHostCreditEntityName('');
                setValue('hostCreditEntityId', null);
              }
            },
            tagOptions: getValues('hostTypeList'),
            tagOptionsSelectionMode: TAG_OPTIONS_SELECTION_MODE.SINGLE,
            tagsFieldLabel: {
              label: 'Host Type *',
              labelStyle: 'text-[#9C9CAF] text-sm md:text-base font-medium'
            }
          }}
        />
        {showHostCreditEntity && (
          <SearchkitSelectFormInput
            {...{
              addNewLabel: '+ Add New Credit Entity',
              clearErrors,
              configureProps: {
                filters: `${TAB_CATEGORY_ATTRIBUTE}:${TAB_CATEGORY_ENUM.HOST_CREDIT_ENTITY}`,
                hitsPerPage: HOST_CREDIT_ENTITY_DROPDOWN_HIT_LIMIT,
                restrictSearchableAttributes: ['hostCreditEntityName']
              },
              dbName: 'hostCreditEntityId',
              disableSearchBox: preventToChangeHostCreditEntity,
              errors,
              indexName: searchQuery
                ? NAVIGATION_INDEX
                : `${NAVIGATION_INDEX}_createdAt_desc`,
              label: {
                className: 'text-[#9C9CAF] text-base font-medium mb-2 block',
                name: 'Credit Entity Name *'
              },
              onClickAddNewEntity: () =>
                setActiveModal({
                  key: activeModalsConfig.ADD_NEW_CREDIT_ENTITY.key,
                  value: true
                }),
              placeholder: 'Select credit entity name',
              searchClient,
              selectedHostCreditEntityName,
              selectFormIcon: 'host-credit-entity-brand-icon.svg',
              setSearchQuery,
              setSelectedHostCreditEntityName,
              setValue: (dbName, value) => {
                setValue(dbName, value);
                trigger(dbName);
              }
            }}
          />
        )}
      </div>
      <NavigateCTA
        {...{
          leftSideButtonProps: {
            label: 'Close',
            onClick: onClickExit
          },
          rightSideButtonProps: {
            disabled: !isValid,
            label: 'Next',
            onClick: handleSubmit(onSubmitHostProfile)
          }
        }}
      />
    </div>
  );
};

export default BasicHostProfileForm;
