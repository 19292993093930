import Image from 'next/image';
import { useRouter } from 'next/router';

import { EditableCartSummaryModal } from '@/components/atomic/molecules';
import {
  Button,
  Text,
  TextWithIcon,
  Tooltip
} from '@/components/atomic/nuclei';
import { CART_STATUS, staticMediaStoreBaseURL } from '@/config/common';
import { parseNumberedLabel } from '@/lib/utils';
import { PAGE_NAME, getPageURL } from '@/services/plannerPortal.service';

const ViewEventCTA = ({ eventId }) => {
  const router = useRouter();
  return (
    <div className='flex text-white border border-white py-0 rounded-lg text-sm font-medium h-8 items-center'>
      <TextWithIcon
        {...{
          alt: 'view event profile',
          className: 'flex !gap-[6px] items-center px-2 cursor-pointer',
          icon: 'view-white-icon.svg',
          iconHeight: 16,
          iconWidth: 16,
          label: 'Event',
          labelStyle: 'self-center',
          onClick: () => {
            router.push(
              getPageURL({
                pageName: PAGE_NAME.EVENT_CART_LIST.label,
                pathParams: {
                  userEventId: eventId
                }
              })
            );
          }
        }}
      />
    </div>
  );
};
const ViewUserEventCTA = ({
  cartName,
  cartNumber,
  createdAt,
  deliveryDate,
  eventId,
  id,
  isReadOnly,
  pickupDate,
  plannerUserName,
  setEditCartSummaryModal,
  status,
  updatedAt
}) => {
  const tooltipConfigList = [
    {
      target: 'event-planner-name',
      text: 'Event Planner Name'
    }
  ];
  return (
    <>
      <div className='flex items-center gap-4'>
        <ViewEventCTA {...{ eventId }} />
        {!isReadOnly && (
          <Button
            {...{
              className:
                'bg-white text-brand px-1 py-[6px] rounded-lg text-sm font-medium',
              iconGroupClassName: 'gap-1 !space-x-1',
              iconHeight: 18,
              iconPosition: 'Left',
              iconURL: `${staticMediaStoreBaseURL}/icons/edit-brand-icon.svg`,
              iconWidth: 18,
              label: 'Cart',
              onClick: (e) => {
                e.stopPropagation();
                setEditCartSummaryModal({
                  showModal: true,
                  cartSummary: {
                    cartName,
                    cartNumber,
                    createdAt,
                    deliveryDate,
                    id,
                    pickupDate,
                    status,
                    updatedAt
                  }
                });
              },
              width: 'w-24'
            }}
          />
        )}
      </div>
      <TextWithIcon
        {...{
          className: 'shadow-none',
          icon: 'planner-white-icon-v2.svg',
          iconHeight: 28,
          iconWidth: 28,
          id: 'event-planner-name',
          label: plannerUserName,
          labelStyle: 'text-base font-medium text-white',
          onClick: (e) => e.stopPropagation()
        }}
      />
      <Tooltip {...{ configList: tooltipConfigList }} />
    </>
  );
};

const HeaderCartDetails = ({ cartName, cartNumber, status }) => {
  const stopEventPropagation = (e) => e.stopPropagation();
  return (
    <div className='flex flex-col gap-3 w-full'>
      <div className='flex justify-between items-center'>
        <Text
          {...{
            className: 'truncate font-semibold text-xl',
            content: cartName,
            id: 'cart-name',
            onHoverCopy: true,
            tooltipText: 'Cart Name'
          }}
        />
        <div className='flex gap-4 items-center'>
          <Text
            {...{
              className: `px-2 py-1 text-base rounded ${CART_STATUS[status.toUpperCase()].className}`,
              content: status,
              id: 'cart-status',
              onClick: stopEventPropagation
            }}
          />
          <TextWithIcon
            {...{
              className: 'shadow-none px-2 rounded bg-white self-center',
              icon: 'cart-brand-icon-v1.svg',
              iconHeight: 24,
              iconWidth: 24,
              id: 'cart-number',
              label: parseNumberedLabel(cartNumber),
              labelStyle:
                'text-base font-semibold text-dim-gray self-center flex py-1',
              onHoverCopy: true,
              onClick: stopEventPropagation,
              tooltipText: 'Cart Number'
            }}
          />
        </div>
      </div>
    </div>
  );
};

const CartInfoSummary = ({
  cartName,
  cartNumber,
  createdAt,
  deliveryDate,
  eventId,
  id,
  isDefault,
  isReadOnly,
  orderId,
  orderNumber,
  pickupDate,
  plannerUserName,
  setEditCartSummaryModal,
  setShowCartEventInfo,
  showCartEventInfo,
  status,
  updatedAt
}) => {
  const deliveryDateToShow = deliveryDate || '';
  const pickupDateToShow = pickupDate || '';

  const tooltipConfigList = [
    {
      target: 'cart-status',
      text: 'Cart Status'
    }
  ];

  return (
    <div
      className='flex flex-wrap justify-between cursor-pointer'
      onClick={() => setShowCartEventInfo(!showCartEventInfo)}
    >
      <HeaderCartDetails
        {...{
          cartName,
          cartNumber,
          deliveryDateToShow,
          orderId,
          orderNumber,
          pickupDateToShow,
          status
        }}
      />
      <div className='flex items-center gap-4 justify-between mt-4 w-full relative'>
        {!isDefault && (
          <ViewUserEventCTA
            {...{
              cartName,
              cartNumber,
              createdAt,
              deliveryDate,
              eventId,
              id,
              isDefault,
              isReadOnly,
              pickupDate,
              plannerUserName,
              setEditCartSummaryModal,
              showCartEventInfo,
              status,
              updatedAt
            }}
          />
        )}
      </div>
      {!isDefault && (
        <div className='absolute -bottom-5 right-4'>
          <Image
            {...{
              alt: 'show cart info',
              className: `cursor-pointer transform ${
                showCartEventInfo ? 'rotate-180' : 'rotate-0'
              }`,
              height: 0,
              src: `${staticMediaStoreBaseURL}/icons/down-angle-circle-icon.svg`,
              style: { hight: 40, width: 40 },
              width: 0
            }}
          />
        </div>
      )}
      <Tooltip {...{ configList: tooltipConfigList }} />
    </div>
  );
};

const CartInfoSummaryOrganism = ({
  cartDetails,
  editCartSummaryModal,
  orderId,
  orderNumber,
  setEditCartSummaryModal,
  setLoading,
  setShowCartEventInfo,
  setShowToast,
  setUpdateCartDetail,
  showCartEventInfo
}) => {
  const {
    cartName,
    cartNumber,
    createdAt,
    deliveryDate,
    id,
    isDefault,
    isReadOnly,
    pickupDate,
    status,
    updatedAt,
    userEventSummary
  } = cartDetails;

  const isOrderedCard = status === CART_STATUS.ORDER.value;

  const { userEventDetails } = userEventSummary || {};
  const [{ eventDate = '', opsUser, userEventId }] = userEventDetails || [{}];
  const plannerUserName = opsUser?.name || 'NA';
  const shouldDisplayEditModalForNonDefaultCart =
    !isDefault && editCartSummaryModal?.showModal;

  const eventId = isOrderedCard ? userEventId : userEventSummary?.id;

  return (
    <div className='p-5'>
      {shouldDisplayEditModalForNonDefaultCart && (
        <EditableCartSummaryModal
          {...{
            editCartSummaryModal,
            setEditCartSummaryModal,
            setLoading,
            setShowToast,
            setShowUserEventProfile: setShowCartEventInfo,
            setUserEventCartDetails: setUpdateCartDetail,
            userEventInformation: userEventSummary
          }}
        />
      )}
      <CartInfoSummary
        {...{
          cartName,
          cartNumber,
          createdAt,
          deliveryDate,
          eventDate,
          eventId,
          id,
          isDefault,
          isReadOnly,
          orderId,
          orderNumber,
          pickupDate,
          plannerUserName,
          setEditCartSummaryModal,
          setShowCartEventInfo,
          showCartEventInfo,
          status,
          updatedAt
        }}
      />
    </div>
  );
};

export default CartInfoSummaryOrganism;
