import { useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import * as yup from 'yup';

import { NavigateCTA } from '@/components/atomic/atoms';
import { AddressModal } from '@/components/atomic/molecules';
import {
  DatePickerWithLabel,
  InputField,
  RichTextEditor,
  SelectFormInput,
  SelectTags,
  Text,
  TextKeyValuePair
} from '@/components/atomic/nuclei';
import { DEFAULT_LOCATION_FOR_EVENT } from '@/config/common';
import useYupValidator from '@/hooks/useYupValidator';
import { transformToNumberValue } from '@/lib/numberStringUtils';
import { submitUpsertHostCreateEventDetails } from '@/services/createUserEvent.service';
import { getPlannerId } from '@/services/identity.service';

const BasicUserEventProfileFormOrganism = ({
  checkoutEvents,
  cityList,
  eventVerticals,
  hostProfileDetail,
  opsUsers,
  setFormStep,
  setLoading,
  setShowToast,
  setUserEventProfileDetail
}) => {
  const [selectedEventDate, setSelectedEventDate] = useState(null);
  const [showAddAddressModal, setShowAddAddressModal] = useState(false);
  const [selectedEventRequestVerticals, setSelectedEventRequestVerticals] =
    useState([]);

  const yupResolver = useYupValidator(
    yup.object().shape({
      checkoutEventId: yup.string().required('Event type is required.'),
      expectedGuestCount: yup
        .number()
        .transform(transformToNumberValue)
        .min(0, 'Expected number of guest must be greater than or equal to 0.')
        .required('Expected number of guest is required.'),
      agentId: yup.string().required('Event planner is required.')
    })
  );

  const onSubmitEventDetails = (eventDetails) =>
    submitUpsertHostCreateEventDetails({
      eventDetails,
      hostProfileDetail,
      selectedEventRequestVerticals,
      setLoading,
      setShowToast,
      setUserEventProfileDetail
    });

  const activeDelightTeamMembers = opsUsers.filter(
    ({ isDelightTeam, isEmployeeActive }) => isDelightTeam && isEmployeeActive
  );

  const {
    clearErrors,
    control,
    formState: { errors, isSubmitted },
    getValues,
    handleSubmit,
    register,
    setValue,
    trigger
  } = useForm({
    resolver: yupResolver,
    values: {
      agentId: activeDelightTeamMembers.find(({ id }) => id === getPlannerId())
        ?.id,
      eventAddress: DEFAULT_LOCATION_FOR_EVENT,
      eventDate: null,
      triageSummary: null
    }
  });
  const { isValid } = useFormState({
    control
  });

  const haflaVerticalErrorMessage =
    selectedEventRequestVerticals.length === 0 && isSubmitted
      ? 'Hafla Request Vertical is required.'
      : null;

  return (
    <div>
      <div className='px-6 flex flex-col gap-4 md:gap-6'>
        <TextKeyValuePair
          {...{
            className: 'flex flex-col gap-2 md:gap-3',
            label: 'Event Details',
            labelClass: 'text-[#F98696] text-lg md:text-2xl font-semibold',
            value: 'Please provide the specific event details of your event.',
            valueClassName: 'text-xs md:text-base font-medium text-[#9C9CAF]'
          }}
        />
        <SelectFormInput
          {...{
            alt: 'user event brand icon',
            clearErrors,
            containerClassName:
              'relative multi-select-epm epm-dropdown border border-platinum rounded text-sm md:text-base placeholder:text-[#9C9CAF]',
            dbName: 'checkoutEventId',
            errors,
            getValues,
            icon: {
              url: 'user-event-brand-icon.svg'
            },
            isClearable: false,
            label: 'Event Type *',
            labelStyle: 'text-[#9C9CAF] font-medium text-sm md:text-base',
            name: 'Checkout Event Id',
            placeholderLabel: 'Event Type',
            register,
            setValue,
            showIcon: true,
            showLabel: true,
            trigger: () => trigger('checkoutEventId'),
            values: checkoutEvents || []
          }}
        />
        <SelectTags
          {...{
            errorMessage: haflaVerticalErrorMessage,
            isEditable: true,
            selectedTagOptions: selectedEventRequestVerticals,
            setSelectedTagOptions: setSelectedEventRequestVerticals,
            tagOptions: eventVerticals,
            tagsFieldLabel: {
              label: 'Hafla Request Vertical(s) *',
              labelStyle: 'text-[#9C9CAF] text-sm md:text-base font-medium'
            }
          }}
        />
        <InputField
          {...{
            alt: 'guest count brand icon',
            className:
              'rounded-lg pl-10 text-sm md:text-base placeholder:text-[#9C9CAF]',
            dbName: 'expectedGuestCount',
            errors,
            iconURL: 'guest-brand-icon.svg',
            inputGroup: 'flex flex-col gap-2 flex-1',
            label: 'Expected # of guests *',
            labelClass: 'text-[#9C9CAF] text-sm md:text-base font-medium',
            placeholder: 'Enter Expected # of guests',
            register: register('expectedGuestCount', {
              valueAsNumber: true,
              onChange: (e) => {
                setValue('expectedGuestCount', e.target.value);
                trigger('expectedGuestCount');
              }
            }),
            type: 'number'
          }}
        />
        <DatePickerWithLabel
          {...{
            datePickerClassName: `create-event-form-picker text-sm font-medium placeholder-silver cursor-pointer focus:outline-none w-full h-8 p-2 border-0 text-sm md:text-base placeholder:text-[#9C9CAF]`,
            dbName: 'eventDate',
            errors,
            label: 'Event Date',
            labelStyle: 'text-[#9C9CAF] text-sm md:text-base font-medium',
            placeholder: 'Choose Event Date',
            selectedEventDate,
            setSelectedEventDate,
            setValue
          }}
        />
        <InputField
          {...{
            alt: 'event address icon',
            className:
              'rounded-lg pl-10 !py-4 text-sm md:text-base placeholder:text-[#9C9CAF]',
            onFocus: () => setShowAddAddressModal(true),
            dbName: 'eventAddress',
            iconURL: 'location-brand-icon.svg',
            inputGroup: 'flex flex-col gap-2',
            label: 'Event Location/City',
            labelClass: 'text-[#9C9CAF] text-sm md:text-base font-medium',
            placeholder: 'Event Location/City',
            register: register('eventAddress.formattedAddress')
          }}
        />
        <SelectFormInput
          {...{
            alt: 'planner icon',
            clearErrors,
            containerClassName:
              'relative multi-select-epm epm-dropdown border border-platinum rounded text-sm md:text-base placeholder:text-[#9C9CAF]',
            dbName: 'agentId',
            dropdownPlacement: 'top',
            errors,
            getValues,
            isClearable: false,
            icon: {
              url: 'planner-brand-icon-v1.svg'
            },
            label: 'Event Planner *',
            labelStyle: 'text-[#9C9CAF] font-medium text-sm md:text-base',
            name: 'Planner',
            placeholderLabel: 'Event Planner *',
            register,
            setValue,
            showIcon: true,
            showLabel: true,
            trigger: () => trigger('agentId'),
            values: activeDelightTeamMembers || []
          }}
        />

        <div className='flex flex-col gap-2'>
          <Text
            {...{
              content: 'Summary',
              className: 'text-[#9C9CAF] text-sm md:text-base font-medium'
            }}
          />
          <div className='border border-platinum rounded-lg overflow-hidden epm-summary-rich-editor'>
            <RichTextEditor
              {...{
                dbKey: 'triageSummary',
                editorStyle: 'overflow-auto h-36 p-2',
                readOnly: false,
                setValue
              }}
            />
          </div>
        </div>
      </div>
      <NavigateCTA
        {...{
          leftSideButtonProps: {
            label: 'Previous',
            onClick: () => setFormStep(0)
          },
          rightSideButtonProps: {
            disabled: !isValid || selectedEventRequestVerticals.length === 0,
            label: 'Create',
            onClick: handleSubmit(onSubmitEventDetails)
          }
        }}
      />
      {showAddAddressModal && (
        <AddressModal
          {...{
            addAddressToggler: () =>
              setShowAddAddressModal(!showAddAddressModal),
            cityList,
            setUserAddress: (address) => setValue('eventAddress', address),
            userAddress: getValues('eventAddress')
          }}
        />
      )}
    </div>
  );
};

export default BasicUserEventProfileFormOrganism;
