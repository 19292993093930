import { Configure, InstantSearch, useStats } from 'react-instantsearch';

import {
  CustomInfiniteHits,
  NoResultsBoundary,
  PageNotFound,
  SectionTitle,
  TerminationPendingExpiredUserEventCard
} from '@/components/atomic/atoms';
import { TextWithIcon } from '@/components/atomic/nuclei';
import {
  DEFAULT_HITS_PER_PAGE_FOR_TERMINATION_PENDING_EXPIRED_USER_EVENT,
  SECTION_NAME
} from '@/config/searchkit/actionCenter';
import { NAVIGATION_INDEX } from '@/config/searchkit/navigation';
import { openTerminationPendingExpiredUserEvents } from '@/services/actionCenter.service';

const TerminationPendingExpiredUserEventBody = ({ selectedPlannerId }) => {
  const { nbHits: resultCount } = useStats();
  const hasResults = resultCount > 0;
  return (
    <div className='flex flex-col gap-4 shadow-navigate-cart rounded-lg bg-white px-3 pb-2 md:p-4 my-4'>
      <div className='flex justify-between items-center'>
        <SectionTitle
          {...{
            resultCount,
            title: 'Expired Events Need Some Attention',
            viewAllCTAProps: {
              className: hasResults
                ? 'cursor-pointer shadow-card py-1 px-2 rounded hidden md:flex'
                : 'hidden',
              onClick: () =>
                openTerminationPendingExpiredUserEvents({
                  selectedPlannerId
                })
            }
          }}
        />
      </div>
      <NoResultsBoundary
        fallback={
          <PageNotFound
            {...{
              className: 'pt-4',
              iconHeight: 114,
              iconWidth: 120,
              notFoundImage: 'dashboard-event-not-found.svg',
              title: `Awesome! You're all caught up`,
              titleStyle: 'font-light text-dim-gray mt-2'
            }}
          />
        }
        isMultiInstanceSearch={true}
      >
        <CustomInfiniteHits
          {...{
            infiniteHitsProps: {
              classNames: {
                item: 'p-0 border-none shadow-none w-full !block',
                list: 'flex gap-4 w-full',
                loadMore: 'hidden',
                root: 'flex flex-1 max-h-[29rem] overflow-auto no-scrollbar'
              },
              hitComponent: ({ hit }) => (
                <TerminationPendingExpiredUserEventCard
                  {...{ hit, selectedPlannerId }}
                />
              ),
              showPrevious: false
            }
          }}
        />
        {hasResults && (
          <TextWithIcon
            {...{
              className:
                'md:hidden justify-end cursor-pointer py-1 px-1 rounded flex mt-[5px] !gap-1',
              iconSuffix: {
                alt: 'arrow down icon',
                icon: 'arrow-down-brand-icon.svg',
                iconStyle: '-rotate-90',
                iconHeight: 12,
                iconWidth: 12
              },
              label: 'View All',
              labelStyle: 'text-xs text-brand font-medium leading-[12px]',
              onClick: () =>
                openTerminationPendingExpiredUserEvents({
                  selectedPlannerId
                })
            }}
          />
        )}
      </NoResultsBoundary>
    </div>
  );
};

const TerminationPendingExpiredUserEvent = ({
  searchClient,
  selectedPlannerId
}) => (
  <InstantSearch
    {...{
      future: {
        preserveSharedStateOnUnmount: true
      },
      indexName: NAVIGATION_INDEX,
      searchClient
    }}
  >
    <Configure
      {...{
        hitsPerPage:
          DEFAULT_HITS_PER_PAGE_FOR_TERMINATION_PENDING_EXPIRED_USER_EVENT,
        plannerId: selectedPlannerId,
        sectionName: SECTION_NAME.EXPIRED_EVENTS
      }}
    />
    <TerminationPendingExpiredUserEventBody {...{ selectedPlannerId }} />
  </InstantSearch>
);

export default TerminationPendingExpiredUserEvent;
