import { useState } from 'react';

import { CartNote, CartPriceSummary } from '@/components/atomic/molecules';
import { Button } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import copyToClipBoard from '@/lib/copyToClipBoard';
import {
  SECTION_LABELS_ENUM,
  onClickGeneratePaymentLink,
  updateCartDetailAPI
} from '@/services/cart.plan.service';

const ActionButtons = ({
  cartDetails,
  cartId,
  isPaymentLinkGenerated,
  onClickCopyWebQuoteLink,
  paymentLinkCTADisabled,
  setIsPaymentLinkGenerated,
  setShowToast,
  userId,
  webQuoteLinkCTADisabled
}) => (
  <div className='flex gap-4 flex-row-reverse'>
    {cartDetails?.isCartEditable && (
      <Button
        {...{
          className: `${
            isPaymentLinkGenerated
              ? 'bg-white text-orange border border-orange'
              : 'bg-brand-gradient text-white'
          } h-12.5 py-4 rounded-md text-base font-medium leading-5 w-full mt-8 ml-auto mb-7 md:mb-0 cursor-pointer`,
          disabled: paymentLinkCTADisabled,
          iconGroupClassName: 'reduce-gap',
          iconHeight: 20,
          iconPosition: 'Left',
          iconURL: `${staticMediaStoreBaseURL}/icons/copy-white-icon.svg`,
          iconWidth: 20,
          label: `${
            isPaymentLinkGenerated ? 'Payment Link' : 'Generate Payment Link'
          }`,
          onClick: () =>
            onClickGeneratePaymentLink({
              cartId,
              copyToClipBoard,
              setIsPaymentLinkGenerated,
              setShowToast,
              userId
            }),
          type: 'submit'
        }}
      />
    )}
    <Button
      {...{
        className: `bg-brand-gradient text-white h-12.5 py-4 rounded-md text-base font-medium leading-5 w-full mt-8 ml-auto mb-7 md:mb-0 cursor-pointer`,
        disabled: webQuoteLinkCTADisabled,
        iconGroupClassName: 'reduce-gap',
        iconHeight: 20,
        iconPosition: 'Left',
        iconURL: `${staticMediaStoreBaseURL}/icons/copy-white-icon.svg`,
        iconWidth: 20,
        label: 'Web Quote Link',
        onClick: onClickCopyWebQuoteLink
      }}
    />
  </div>
);

const WIPCartPriceSummaryAndNoteWithCTAOrganism = ({
  bottomSectionRef,
  commonProps,
  isPriceSummaryAvailable,
  itemsForPriceSummary,
  onClickCopyWebQuoteLink,
  paymentLinkCTADisabled
}) => {
  const {
    cartDetails,
    resequenceCartItems,
    setCartDetails,
    setShowToast,
    unSavedChangesErrorMessage,
    updateEditViewSection,
    updateSectionLabel,
    webQuoteLinkCTADisabled
  } = commonProps;

  const { id: cartId, userCartId: userId } = cartDetails;

  const {
    derivedValues,
    id,
    isCartEditable,
    lumpSumDiscount,
    nonTaxableCharge
  } = cartDetails;

  const [isPaymentLinkGenerated, setIsPaymentLinkGenerated] = useState(false);

  return (
    <div
      ref={bottomSectionRef}
      className='flex flex-row gap-6'
    >
      <div className='w-1/2'>
        {isPriceSummaryAvailable && (
          <CartPriceSummary
            {...{
              cartDetails: {
                cartItems: itemsForPriceSummary,
                derivedValues,
                id,
                lumpSumDiscount,
                nonTaxableCharge
              },
              isCartEditable,
              priceSummaryViewEditMode:
                updateEditViewSection[SECTION_LABELS_ENUM.PRICE_SUMMARY],
              setPriceSummaryViewEditMode: (newState) => {
                updateSectionLabel({
                  newState,
                  section: SECTION_LABELS_ENUM.PRICE_SUMMARY
                });
              },
              unSavedChangesErrorMessage:
                unSavedChangesErrorMessage[SECTION_LABELS_ENUM.PRICE_SUMMARY],
              updateCartDetailAPI: (updatedCartDetail) =>
                updateCartDetailAPI({
                  cartId,
                  setCartDetails,
                  setShowToast,
                  updatedCartDetail,
                  userId
                })
            }}
          />
        )}
      </div>
      {!resequenceCartItems && (
        <div className='w-1/2'>
          <CartNote
            {...{
              cartDetails,
              isCartEditable,
              cartNoteViewEditMode:
                updateEditViewSection[SECTION_LABELS_ENUM.CART_NOTE],
              setCartNoteViewEditMode: (newState) => {
                updateSectionLabel({
                  newState,
                  section: SECTION_LABELS_ENUM.CART_NOTE
                });
              },
              unSavedChangesErrorMessage:
                unSavedChangesErrorMessage[SECTION_LABELS_ENUM.CART_NOTE],
              title: 'Notes',
              updateCartDetailAPI: (updatedCartDetail) =>
                updateCartDetailAPI({
                  cartId,
                  setCartDetails,
                  setShowToast,
                  updatedCartDetail,
                  userId
                })
            }}
          />
          <ActionButtons
            {...{
              cartDetails,
              cartId,
              isPaymentLinkGenerated,
              onClickCopyWebQuoteLink,
              paymentLinkCTADisabled,
              setIsPaymentLinkGenerated,
              setShowToast,
              userId,
              webQuoteLinkCTADisabled
            }}
          />
        </div>
      )}
    </div>
  );
};

export default WIPCartPriceSummaryAndNoteWithCTAOrganism;
