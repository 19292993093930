import Image from 'next/image';
import { useRouter } from 'next/router';
import { useRef } from 'react';

import { NavigationMenu } from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';
import useOnClickOutside from '@/hooks/useOnClickOutside';

const menuIconPair = [
  {
    alt: 'menu-white-icon',
    className: 'action-center-menu-white-icon'
  },
  {
    alt: 'menu-brand-icon',
    className: 'hidden action-center-menu-red-icon'
  }
];

const NavigationMenuContainer = ({
  isMenuOpen,
  menuClass,
  plannerName,
  setIsMenuOpen,
  setOpenAddHostModal
}) => {
  const router = useRouter();
  const menuRef = useRef(null);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  useOnClickOutside(menuRef, () => setIsMenuOpen(false));

  return (
    <div
      className='flex gap-6 cursor-pointer relative z-20'
      ref={menuRef}
    >
      <div
        className='relative welcome-menu rounded-lg w-10 h-10'
        onClick={toggleMenu}
      >
        {menuIconPair.map(({ alt, className }, id) => (
          <Image
            key={`${alt}_${id}`}
            {...{
              alt: isMenuOpen ? 'close menu icon' : 'menu brand icon',
              className: `relative left-[5px] top-[5px] cursor-pointer ${className}`,
              height: 0,
              onClick: toggleMenu,
              src: `${staticMediaStoreBaseURL}/icons/${isMenuOpen ? 'menu-close-brand-icon.svg' : 'menu-brand-icon.svg'}`,
              style: { height: 30, width: 30 },
              width: 0
            }}
          />
        ))}
        <Image
          alt='menu white icon'
          className='hidden menu-red-icon relative left-[5px] top-[5px] cursor-pointer'
          height={0}
          src={`${staticMediaStoreBaseURL}/icons/menu-white-icon-v1.svg`}
          style={{ height: 30, width: 30 }}
          width={0}
        />
      </div>
      {isMenuOpen && (
        <NavigationMenu
          {...{
            menuClass,
            plannerName,
            router,
            setOpenAddHostModal,
            toggleMenu
          }}
        />
      )}
    </div>
  );
};

export default NavigationMenuContainer;
