import { Highlight, Menu, RefinementList } from 'react-instantsearch';

import { NumericFilterWithDatePicker } from '@/components/atomic/atoms';
import { RadioButtons } from '@/components/atomic/nuclei';
import {
  CART_STATUS,
  HOST_INTERACTION_STATUS,
  NAVIGATION_ENTITY_TYPE,
  ORDER_STATUS,
  USER_EVENT_STAGE
} from '@/config/common';
import {
  DATE_FILTER_ATTRIBUTE,
  DEFAULT_FILTER_OPTIONS_LIMIT,
  NAVIGATION_INDEX,
  RECORDS_OPTIONS,
  TAB_CATEGORY_ENUM,
  TRANSFORMED_USER_EVENT_STAGE
} from '@/config/searchkit/navigation';
import {
  DATE_RANGE_ITEMS_FOR_CART_EVENT_DATE,
  DATE_RANGE_ITEMS_FOR_EVENT_DATE,
  DATE_RANGE_ITEMS_FOR_USER_EVENT_DATE,
  DEFAULT_NUMERIC_REFINEMENT
} from '@/helpers/searchkit';
import { handleOnClickZendesk } from '@/helpers/zendeskTicket';
import { convertToStandardMobileNumberFormat } from '@/lib/mobileNumberValidation';
import {
  convertToCurrencyFormat,
  parseFormatPriceNumberValueFromAPI
} from '@/lib/numberStringUtils';
import {
  convertToDateTimeString,
  convertToShortMonthDateFormat
} from '@/lib/time';
import { parseNumberedLabel } from '@/lib/utils';
import { getPlannerName } from '@/services/identity.service';
import { getEditOrderURL } from '@/services/opsPortal.service';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';

const parseUserEventEntity = (hit) => {
  const {
    entityId,
    eventDate,
    eventTime,
    hostId,
    hostMobile,
    hostName,
    hostNumber,
    plannerName,
    stage,
    userEventNumber,
    zendeskTicketNumber
  } = hit;

  return {
    entityId,
    body: {
      entityNumber: {
        additionalLabelStyle: 'hover:text-brand',
        alt: 'user event number',
        clipboardText: `# ${userEventNumber}`,
        icon: 'user-event-brand-icon.svg',
        id: 'event-number',
        label: (
          <span>
            {'# '}
            <Highlight
              {...{
                attribute: 'userEventNumber',
                className: 'entity-number-highlight',
                hit
              }}
            />
          </span>
        ),
        onClick: () =>
          window.open(
            getPageURL({
              pageName: PAGE_NAME.HOST_EVENT_PROFILE.label,
              pathParams: {
                userEventId: entityId
              }
            }),
            '_blank'
          ),
        onHoverCopy: true,
        tooltipText: 'User Event Number'
      },
      entityStatus: {
        id: 'event-stage',
        statusClass: USER_EVENT_STAGE[stage].class,
        value: USER_EVENT_STAGE[stage].label
      }
    },
    header: {
      subTitle: {
        additionalLabelStyle: zendeskTicketNumber ? 'hover:text-brand' : '',
        alt: 'zendesk ticket number',
        clipboardText: `# ${zendeskTicketNumber}`,
        icon: 'zendesk-with-frame.svg',
        id: 'zendesk-ticket-number',
        isBorder: true,
        onClick: () => handleOnClickZendesk(zendeskTicketNumber),
        onHoverCopy: true,
        tooltipText: 'Zendesk Ticket Number',
        value: (
          <span>
            {'# '}
            <Highlight
              {...{
                attribute: 'zendeskTicketNumber',
                className: 'entity-number-highlight',
                hit
              }}
            />
          </span>
        )
      },
      title: (
        <Highlight
          {...{
            attribute: 'userEventName',
            hit
          }}
        />
      ),
      onHoverTitleSubscriptCopy: true,
      titleSubscript: convertToStandardMobileNumberFormat(hostMobile) || 'NA',
      titleSubscriptId: 'host-mobile-number',
      titleSubscriptTooltipText: 'Host Mobile Number'
    },
    hoverCard: {
      textWithIconList: [
        {
          alt: 'planner name',
          icon: 'planner-brand-icon-v1.svg',
          id: 'event-planner-name',
          label: plannerName,
          onHoverCopy: true,
          tooltipText: 'Event Planner Name'
        },
        {
          alt: 'last updated',
          icon: 'date-brand-icon.svg',
          id: 'event-date',
          label: `${convertToDateTimeString({
            date: eventDate,
            time: eventTime
          })}`,
          onHoverCopy: true,
          tooltipText: 'Event Date'
        },
        {
          additionalLabelStyle: 'hover:text-brand',
          alt: 'host name icon',
          icon: 'host-brand-v1-icon.svg',
          id: 'host-name',
          label: hostName,
          onClick: () =>
            window.open(
              getPageURL({
                pageName: PAGE_NAME.HOST_EVENT_LIST.label,
                pathParams: {
                  userId: hostId
                }
              }),
              '_blank'
            ),
          onHoverCopy: true,
          tooltipText: 'Host Name'
        },
        {
          additionalLabelStyle: 'hover:text-brand',
          alt: 'host number icon',
          icon: 'host-number-brand-v1-icon.svg',
          id: 'host-number',
          label: parseNumberedLabel(hostNumber),
          onClick: () =>
            window.open(
              getPageURL({
                pageName: PAGE_NAME.HOST_PROFILE.label,
                pathParams: {
                  userId: hostId
                }
              }),
              '_blank'
            ),
          onHoverCopy: true,
          tooltipText: 'Host Number'
        }
      ]
    },
    icon: {
      alt: 'event',
      name: 'list-card-event.svg'
    },
    onClick: () => {
      window.open(
        getPageURL({
          pageName: PAGE_NAME.EVENT_CART_LIST.label,
          pathParams: { userEventId: entityId }
        }),
        '_blank'
      );
    },
    tooltipConfigListForMainCard: [
      {
        target: 'event-stage',
        text: 'Event Stage'
      }
    ]
  };
};

const parseCartEntity = (hit) => {
  const {
    cartNumber,
    cartTotal,
    entityId,
    hostId,
    hostMobile,
    hostName,
    hostNumber,
    plannerName,
    status,
    userEventId,
    userEventNumber
  } = hit;
  const { className: cartStatusClass, label: cartStatusLabel } =
    CART_STATUS[status.toUpperCase()];

  return {
    entityId,
    body: {
      entityNumber: {
        alt: 'cart number',
        clipboardText: `# ${cartNumber}`,
        icon: 'cart-brand-icon-v1.svg',
        id: 'cart-number',
        label: (
          <span>
            {'# '}
            <Highlight
              {...{
                attribute: 'cartNumber',
                className: 'entity-number-highlight',
                hit
              }}
            />
          </span>
        ),
        onHoverCopy: true,
        tooltipText: 'Cart Number'
      },
      entityStatus: {
        id: 'cart-status',
        statusClass: `px-2 py-1 self-start rounded text-sm font-medium ${cartStatusClass}`,
        value: cartStatusLabel
      }
    },
    header: {
      subTitle: {
        additionalLabelStyle: 'hover:text-brand',
        alt: 'user event number',
        clipboardText: `# ${userEventNumber}`,
        icon: 'user-event-brand-icon.svg',
        id: 'event-number',
        isBorder: true,
        onClick: () =>
          window.open(
            getPageURL({
              pageName: PAGE_NAME.HOST_EVENT_PROFILE.label,
              pathParams: {
                userEventId
              }
            }),
            '_blank'
          ),
        onHoverCopy: true,
        tooltipText: 'Event Number',
        value: (
          <span>
            {'# '}
            <Highlight
              {...{
                attribute: 'userEventNumber',
                className: 'entity-number-highlight',
                hit
              }}
            />
          </span>
        )
      },
      title: (
        <Highlight
          {...{
            attribute: 'cartName',
            hit
          }}
        />
      ),
      onHoverTitleSubscriptCopy: true,
      titleSubscript: convertToStandardMobileNumberFormat(hostMobile) || 'NA',
      titleSubscriptId: 'host-mobile-number',
      titleSubscriptTooltipText: 'Host Mobile Number'
    },
    hoverCard: {
      textWithIconList: [
        {
          alt: 'planner name',
          icon: 'planner-brand-icon-v1.svg',
          id: 'event-planner-name',
          label: plannerName,
          onHoverCopy: true,
          tooltipText: 'Event Planner Name'
        },
        {
          alt: 'cart total',
          icon: 'price-aed-brand-icon.svg',
          id: 'cart-value',
          label: convertToCurrencyFormat({
            value: parseFormatPriceNumberValueFromAPI(cartTotal)
          }),
          onHoverCopy: true,
          tooltipText: 'Cart value'
        },
        {
          additionalLabelStyle: 'hover:text-brand',
          alt: 'host name icon',
          icon: 'host-brand-v1-icon.svg',
          id: 'host-name',
          label: hostName,
          onHoverCopy: true,
          tooltipText: 'Host Name',
          onClick: () =>
            window.open(
              getPageURL({
                pageName: PAGE_NAME.HOST_EVENT_LIST.label,
                pathParams: {
                  userId: hostId
                }
              }),
              '_blank'
            )
        },
        {
          additionalLabelStyle: 'hover:text-brand',
          alt: 'host number icon',
          icon: 'host-number-brand-v1-icon.svg',
          id: 'host-number',
          label: parseNumberedLabel(hostNumber),
          onClick: () =>
            window.open(
              getPageURL({
                pageName: PAGE_NAME.HOST_PROFILE.label,
                pathParams: {
                  userId: hostId
                }
              }),
              '_blank'
            ),
          onHoverCopy: true,
          tooltipText: 'Host Number'
        }
      ]
    },
    icon: {
      alt: 'cart',
      name: 'list-card.svg'
    },
    onClick: () => {
      const pageName =
        status === CART_STATUS.ORDER.value
          ? PAGE_NAME.ORDERED_CART.label
          : PAGE_NAME.PLANNER_CART.label;
      window.open(
        getPageURL({
          pageName,
          pathParams: { cartId: entityId }
        }),
        '_blank'
      );
    },
    tooltipConfigListForMainCard: [
      {
        target: 'cart-status',
        text: 'Cart Status'
      }
    ]
  };
};

const parseOrderEntity = (hit) => {
  const {
    bookingDate,
    entityId,
    orderTotal,
    hostId,
    hostMobile,
    hostName,
    hostNumber,
    orderNumber,
    status,
    userEventId,
    userEventNumber
  } = hit;
  return {
    entityId,
    body: {
      entityNumber: {
        alt: 'order number',
        clipboardText: `# ${orderNumber}`,
        icon: 'order-brand-icon-v1.svg',
        id: 'order-number',
        label: (
          <span>
            {'# '}
            <Highlight
              {...{
                attribute: 'orderNumber',
                className: 'entity-number-highlight',
                hit
              }}
            />
          </span>
        ),
        onHoverCopy: true,
        tooltipText: 'Order Number'
      },
      entityStatus: {
        id: 'order-status',
        statusClass: `px-2 py-1 self-start rounded text-sm font-medium ${ORDER_STATUS[status]?.class}`,
        value: ORDER_STATUS[status]?.label || 'NA'
      }
    },
    header: {
      subTitle: {
        additionalLabelStyle: 'hover:text-brand',
        alt: 'user event number',
        clipboardText: `# ${userEventNumber}`,
        icon: 'user-event-brand-icon.svg',
        id: 'event-number',
        isBorder: true,
        onClick: () =>
          window.open(
            getPageURL({
              pageName: PAGE_NAME.HOST_EVENT_PROFILE.label,
              pathParams: {
                userEventId
              }
            }),
            '_blank'
          ),
        onHoverCopy: true,
        tooltipText: 'Event Number',
        value: (
          <span>
            {'# '}
            <Highlight
              {...{
                attribute: 'userEventNumber',
                className: 'entity-number-highlight',
                hit
              }}
            />
          </span>
        )
      },
      title: (
        <Highlight
          {...{
            attribute: 'orderName',
            hit
          }}
        />
      ),
      onHoverTitleSubscriptCopy: true,
      titleSubscript: convertToStandardMobileNumberFormat(hostMobile) || 'NA',
      titleSubscriptId: 'host-mobile-number',
      titleSubscriptTooltipText: 'Host Mobile Number'
    },
    hoverCard: {
      cta: {
        label: 'View Order',
        onClick: (e) => {
          e.stopPropagation();
          window.open(
            getPageURL({
              pageName: PAGE_NAME.ORDER_VIEW.label,
              pathParams: { orderId: entityId }
            }),
            '_blank'
          );
        }
      },
      textWithIconList: [
        {
          alt: 'booking date',
          icon: 'order-date.svg',
          id: 'order-booking-date',
          label: convertToShortMonthDateFormat(bookingDate),
          onHoverCopy: true,
          tooltipText: 'Booking Date'
        },
        {
          alt: 'order total',
          icon: 'price-aed-brand-icon.svg',
          id: 'order-value',
          label: convertToCurrencyFormat({
            value: parseFormatPriceNumberValueFromAPI(orderTotal)
          }),
          onHoverCopy: true,
          tooltipText: 'Order value'
        },
        {
          additionalLabelStyle: 'hover:text-brand',
          alt: 'host name icon',
          icon: 'host-brand-v1-icon.svg',
          id: 'host-name',
          label: hostName,
          onClick: () =>
            window.open(
              getPageURL({
                pageName: PAGE_NAME.HOST_EVENT_LIST.label,
                pathParams: {
                  userId: hostId
                }
              }),
              '_blank'
            ),
          onHoverCopy: true,
          tooltipText: 'Host name'
        },
        {
          additionalLabelStyle: 'hover:text-brand',
          alt: 'host number icon',
          icon: 'host-number-brand-v1-icon.svg',
          id: 'host-number',
          label: parseNumberedLabel(hostNumber),
          onClick: () =>
            window.open(
              getPageURL({
                pageName: PAGE_NAME.HOST_PROFILE.label,
                pathParams: {
                  userId: hostId
                }
              }),
              '_blank'
            ),
          onHoverCopy: true,
          tooltipText: 'Host number'
        }
      ]
    },
    icon: {
      alt: 'order',
      name: 'list-card-order.svg'
    },
    onClick: () =>
      window.open(getEditOrderURL({ orderId: entityId }), '_blank'),
    tooltipConfigListForMainCard: [
      {
        target: 'order-status',
        text: 'Order Status'
      }
    ]
  };
};

const parseHostEntity = (hit) => {
  const {
    email,
    entityId,
    hostCreditEntityId,
    hostCreditEntityName,
    hostMobile,
    hostNumber,
    hostSegment,
    interactionStatus
  } = hit;
  const parsedHostCreditEntityName =
    hostCreditEntityName === 'NA' ? null : hostCreditEntityName;

  return {
    entityId,
    body: {
      entityNumber: {
        additionalLabelStyle: 'hover:text-brand',
        alt: 'host number',
        clipboardText: `# ${hostNumber}`,
        icon: 'host-number-brand-v1-icon.svg',
        id: 'host-number',
        label: (
          <span>
            {'# '}
            <Highlight
              {...{
                attribute: 'hostNumber',
                className: 'entity-number-highlight',
                hit
              }}
            />
          </span>
        ),
        onClick: () =>
          window.open(
            getPageURL({
              pageName: PAGE_NAME.HOST_PROFILE.label,
              pathParams: {
                userId: entityId
              }
            }),
            '_blank'
          ),
        onHoverCopy: true,
        tooltipText: 'Host number'
      },
      entityStatus: {
        id: 'host-interaction-status',
        statusClass: `${HOST_INTERACTION_STATUS[interactionStatus].className} px-2 py-1 self-start rounded text-sm font-medium`,
        value: HOST_INTERACTION_STATUS[interactionStatus].label
      }
    },
    header: {
      subTitle: {
        additionalLabelStyle: hostCreditEntityId ? 'hover:text-brand' : '',
        alt: 'host credit entity name',
        clipboardText: hostCreditEntityName,
        icon: 'host-credit-entity-brand-icon.svg',
        id: 'host-credit-entity-name',
        isBorder: true,
        onClick: () =>
          hostCreditEntityId &&
          window.open(
            getPageURL({
              pageName: PAGE_NAME.HOST_CREDIT_ENTITY_HOST_LIST.label,
              pathParams: {
                hostCreditEntityId
              }
            }),
            '_blank'
          ),
        onHoverCopy: true,
        tooltipText: 'Host credit entity name',
        value: parsedHostCreditEntityName ? (
          <Highlight
            {...{
              attribute: 'hostCreditEntityName',
              hit
            }}
          />
        ) : (
          'NA'
        )
      },
      title: (
        <Highlight
          {...{
            attribute: 'hostName',
            hit
          }}
        />
      ),
      onHoverTitleSubscriptCopy: true,
      titleSubscript: convertToStandardMobileNumberFormat(hostMobile) || 'NA',
      titleSubscriptId: 'host-mobile-number',
      titleSubscriptTooltipText: 'Host Mobile Number'
    },
    hoverCard: {
      textWithIconList: [
        {
          alt: 'host type',
          icon: 'host-type-brand-v1-icon.svg',
          id: 'host-type',
          label: hostSegment || 'NA',
          onHoverCopy: true,
          tooltipText: 'Host Type'
        },
        {
          alt: 'email icon',
          icon: 'email-brand-icon.svg',
          id: 'host-email-id',
          label: email || 'NA',
          onHoverCopy: true,
          tooltipText: 'Host Email ID'
        }
      ]
    },
    icon: {
      alt: 'host gradient icon',
      name: 'host-gradient-icon.svg'
    },
    onClick: () => {
      window.open(
        getPageURL({
          pageName: PAGE_NAME.HOST_EVENT_LIST.label,
          pathParams: { userId: entityId }
        }),
        '_blank'
      );
    },
    tooltipConfigListForMainCard: [
      {
        target: 'host-interaction-status',
        text: 'Host Interaction Status'
      }
    ]
  };
};

const parseHostCreditEntity = (hit) => {
  const { accountManagerName, entityId, hostCreditEntityNumber } = hit;
  return {
    entityId,
    body: {
      entityNumber: {
        additionalLabelStyle: 'hover:text-brand',
        additionalTooltipClass: 'ml-[-9rem] !top-0',
        alt: 'host credit entity number',
        clipboardText: `# ${hostCreditEntityNumber}`,
        icon: 'host-credit-entity-brand-icon.svg',
        id: 'host-credit-entity-number',
        label: (
          <span>
            {'# '}
            <Highlight
              {...{
                attribute: 'hostCreditEntityNumber',
                className: 'entity-number-highlight',
                hit
              }}
            />
          </span>
        ),
        onClick: () =>
          window.open(
            getPageURL({
              pageName: PAGE_NAME.HOST_CREDIT_ENTITY_HOST_PROFILE.label,
              pathParams: { hostCreditEntityId: entityId }
            }),
            '_blank'
          ),
        onHoverCopy: true,
        tooltipText: 'Credit Entity Number'
      }
    },

    header: {
      subTitle: {
        alt: 'account manager',
        icon: 'planner-brand-icon-v1.svg',
        id: 'planner-name',
        isBorder: true,
        onHoverCopy: true,
        tooltipText: 'Planner Name',
        value: accountManagerName
      },
      title: (
        <Highlight
          {...{
            attribute: 'hostCreditEntityName',
            hit
          }}
        />
      )
    },
    icon: {
      alt: 'host credit entity',
      name: 'list-cart-host-credit-entity.svg'
    },
    onClick: () =>
      window.open(
        getPageURL({
          pageName: PAGE_NAME.HOST_CREDIT_ENTITY_HOST_LIST.label,
          pathParams: { hostCreditEntityId: entityId }
        }),
        '_blank'
      )
  };
};

export const transformSearchResultList = ({ searchResults }) => {
  const transformFunctionMap = {
    [NAVIGATION_ENTITY_TYPE.CART]: parseCartEntity,
    [NAVIGATION_ENTITY_TYPE.HOST]: parseHostEntity,
    [NAVIGATION_ENTITY_TYPE.ORDER]: parseOrderEntity,
    [NAVIGATION_ENTITY_TYPE.USER_EVENT]: parseUserEventEntity,
    [NAVIGATION_ENTITY_TYPE.HOST_CREDIT_ENTITY]: parseHostCreditEntity
  };

  return searchResults.map(({ entityType, ...rest }) =>
    transformFunctionMap[entityType]({ ...rest, entityType })
  );
};

const classNameGroup = {
  checkbox:
    'content-searchkit-checkbox searchkit-checkbox checked:content-searchkit-checkbox-checked',
  count: 'bg-brand text-white border-none font-medium text-xs',
  labelText:
    'text-sm text-dim-gray font-medium searchkit-label-text cursor-pointer',
  list: 'flex flex-col gap-4 max-h-80 overflow-auto',
  searchBox: 'searchkit-search-box',
  searchableForm: 'searchkit-search-box-form',
  selectedItem: 'searchkit-selected-item',
  showMore: 'searchkit-filter-show-more-button'
};

export const getFilterConfig = ({
  clearBookingDateFilter,
  clearCreatedAtDateFilter,
  clearEventDateFilter,
  defaultRefinementForEventDate,
  entityLabel,
  handleOptionChange,
  memoizedCartStatusTransform,
  memoizedHostInteractionStatusTransform,
  memoizedHostTypeTransform,
  memoizedOrderStatusTransform,
  memoizedUserEventStageTransform,
  radioInputProps,
  selectedOption,
  setClearBookingDateFilter,
  setClearCreatedAtDateFilter,
  setClearEventDateFilter,
  setSelectedOption,
  setUiState
}) => {
  const PLANNER_NAME_FILTER = {
    componentProps: {
      attribute: 'plannerName',
      classNames: {
        ...classNameGroup,
        list: `${classNameGroup.list} mt-4`,
        searchableForm: 'searchkit-search-box-form',
        searchBox: 'searchkit-search-box searchkit-icon',
        selectedItem: 'searchkit-selected-item'
      },
      limit: DEFAULT_FILTER_OPTIONS_LIMIT,
      searchable: true,
      searchablePlaceholder: 'Search',
      showMore: true,
      sortBy: ['isRefined']
    },
    Component: RefinementList,
    headerProps: {
      heading: 'Planner Name',
      includedAttributes: 'plannerName'
    },
    showFilter: selectedOption !== RECORDS_OPTIONS.MY_RECORDS
  };

  const MY_RECORDS_FILTER = {
    componentProps: {
      handleOptionChange,
      onChangeOptionUiState: () =>
        setUiState((prevUiState) => ({
          ...prevUiState,
          [NAVIGATION_INDEX]: {
            ...prevUiState[NAVIGATION_INDEX],
            refinementList: {
              ...(prevUiState[NAVIGATION_INDEX]?.refinementList || {}),
              plannerName:
                selectedOption === RECORDS_OPTIONS.MY_RECORDS
                  ? [getPlannerName()]
                  : []
            }
          }
        })),
      radioInputProps,
      selectedOption
    },
    Component: RadioButtons,
    headerProps: {
      isClearable: false,
      heading: entityLabel,
      includedAttributes: [],
      onClick: () => {
        setSelectedOption(RECORDS_OPTIONS.ALL_RECORDS);
      }
    }
  };

  return {
    [TAB_CATEGORY_ENUM.ORDER]: [
      MY_RECORDS_FILTER,
      {
        componentProps: {
          attribute: DATE_FILTER_ATTRIBUTE.BOOKING_DATE,
          clearDateFilter: clearBookingDateFilter,
          customRangeKey: 'customRangeForBookingDate',
          defaultRefinement: DEFAULT_NUMERIC_REFINEMENT,
          items: [{}],
          showAdditionalFilters: false
        },
        Component: NumericFilterWithDatePicker,
        headerProps: {
          heading: 'Booking Date',
          includedAttributes: DATE_FILTER_ATTRIBUTE.BOOKING_DATE,
          onClick: () => {
            setClearBookingDateFilter(!clearBookingDateFilter);
          }
        }
      },
      {
        componentProps: {
          attribute: DATE_FILTER_ATTRIBUTE.EVENT_DATE,
          clearDateFilter: clearEventDateFilter,
          customRangeKey: 'customRangeForEventDate',
          defaultRefinement: defaultRefinementForEventDate,
          items: Object.values(DATE_RANGE_ITEMS_FOR_EVENT_DATE)
        },
        Component: NumericFilterWithDatePicker,
        headerProps: {
          heading: 'Event Date',
          includedAttributes: DATE_FILTER_ATTRIBUTE.EVENT_DATE,
          onClick: () => {
            setClearEventDateFilter(!clearEventDateFilter);
          }
        }
      },
      PLANNER_NAME_FILTER,
      {
        Component: Menu,
        componentProps: {
          attribute: 'status',
          classNames: {
            count: classNameGroup.count,
            item: 'h-5',
            label:
              'searchkit-filter-menu-label text-sm text-dim-gray font-medium cursor-pointer',
            list: 'flex flex-col gap-4 mt-4',
            selectedItem: 'searchkit-filter-menu-selected-item',
            showMore: classNameGroup.showMore
          },
          limit: Object.keys(ORDER_STATUS).length,
          transformItems: memoizedOrderStatusTransform
        },
        headerProps: {
          heading: 'Order Status',
          includedAttributes: 'status'
        }
      }
    ],
    [TAB_CATEGORY_ENUM.CART]: [
      MY_RECORDS_FILTER,
      PLANNER_NAME_FILTER,
      {
        Component: Menu,
        componentProps: {
          attribute: 'status',
          classNames: {
            count: classNameGroup.count,
            item: 'h-5',
            label:
              'searchkit-filter-menu-label text-sm text-dim-gray font-medium cursor-pointer',
            list: 'flex flex-col gap-4 mt-4',
            selectedItem: 'searchkit-filter-menu-selected-item',
            showMore: classNameGroup.showMore
          },
          transformItems: memoizedCartStatusTransform
        },
        headerProps: {
          heading: 'Cart Status',
          includedAttributes: 'status'
        }
      },
      {
        componentProps: {
          attribute: DATE_FILTER_ATTRIBUTE.EVENT_DATE,
          clearDateFilter: clearEventDateFilter,
          customRangeKey: 'customRangeForCartEventDate',
          defaultRefinement: defaultRefinementForEventDate,
          items: Object.values(DATE_RANGE_ITEMS_FOR_CART_EVENT_DATE)
        },
        Component: NumericFilterWithDatePicker,
        headerProps: {
          heading: 'Event Date',
          includedAttributes: DATE_FILTER_ATTRIBUTE.EVENT_DATE,
          onClick: () => {
            setClearEventDateFilter(!clearEventDateFilter);
          }
        }
      },
      {
        componentProps: {
          attribute: DATE_FILTER_ATTRIBUTE.CREATED_AT_DATE,
          clearDateFilter: clearCreatedAtDateFilter,
          customRangeKey: 'customRangeForCartCreationDate',
          defaultRefinement: DEFAULT_NUMERIC_REFINEMENT,
          items: [{}],
          showAdditionalFilters: false
        },
        Component: NumericFilterWithDatePicker,
        headerProps: {
          heading: 'Cart Creation Date',
          includedAttributes: DATE_FILTER_ATTRIBUTE.CREATED_AT_DATE,
          onClick: () => {
            setClearCreatedAtDateFilter(!clearCreatedAtDateFilter);
          }
        }
      }
    ],
    [TAB_CATEGORY_ENUM.HOST]: [
      {
        Component: Menu,
        componentProps: {
          attribute: 'interactionStatus',
          classNames: {
            count: classNameGroup.count,
            item: 'h-5',
            label:
              'searchkit-filter-menu-label text-sm text-dim-gray font-medium cursor-pointer',
            list: 'flex flex-col gap-4 mt-4',
            selectedItem: 'searchkit-filter-menu-selected-item',
            showMore: classNameGroup.showMore
          },
          limit: Object.keys(HOST_INTERACTION_STATUS).length,
          transformItems: memoizedHostInteractionStatusTransform
        },
        headerProps: {
          heading: 'Host Interaction Status',
          includedAttributes: 'interactionStatus'
        }
      },
      {
        Component: Menu,
        componentProps: {
          attribute: 'hostSegment',
          classNames: {
            count: classNameGroup.count,
            item: 'h-5',
            label:
              'searchkit-filter-menu-label text-sm text-dim-gray font-medium cursor-pointer',
            list: 'flex flex-col gap-4 mt-4',
            selectedItem: 'searchkit-filter-menu-selected-item',
            showMore: classNameGroup.showMore
          },
          transformItems: memoizedHostTypeTransform
        },
        headerProps: {
          heading: 'Host Type',
          includedAttributes: 'hostSegment'
        }
      }
    ],
    [TAB_CATEGORY_ENUM.USER_EVENT]: [
      MY_RECORDS_FILTER,
      {
        componentProps: {
          attribute: DATE_FILTER_ATTRIBUTE.EVENT_DATE,
          clearDateFilter: clearEventDateFilter,
          customRangeKey: 'customRangeForUserEventDate',
          defaultRefinement: defaultRefinementForEventDate,
          items: Object.values(DATE_RANGE_ITEMS_FOR_USER_EVENT_DATE)
        },
        Component: NumericFilterWithDatePicker,
        headerProps: {
          heading: 'Event Date',
          includedAttributes: DATE_FILTER_ATTRIBUTE.EVENT_DATE,
          onClick: () => {
            setClearEventDateFilter(!clearEventDateFilter);
          }
        }
      },
      PLANNER_NAME_FILTER,
      {
        Component: Menu,
        componentProps: {
          attribute: 'stage',
          classNames: {
            count: classNameGroup.count,
            item: 'h-5',
            label:
              'searchkit-filter-menu-label text-sm text-dim-gray font-medium cursor-pointer',
            list: 'flex flex-col gap-4 mt-4',
            selectedItem: 'searchkit-filter-menu-selected-item',
            showMore: classNameGroup.showMore
          },
          limit: Object.keys(TRANSFORMED_USER_EVENT_STAGE).length,
          transformItems: memoizedUserEventStageTransform
        },
        headerProps: {
          heading: 'Event Stage',
          includedAttributes: 'stage'
        }
      },
      {
        componentProps: {
          attribute: 'eventType',
          classNames: {
            ...classNameGroup,
            list: `${classNameGroup.list} mt-4`,
            searchableForm: 'searchkit-search-box-form',
            searchBox: 'searchkit-search-box searchkit-icon',
            selectedItem: 'searchkit-selected-item'
          },
          limit: DEFAULT_FILTER_OPTIONS_LIMIT,
          searchable: true,
          searchablePlaceholder: 'Search',
          showMore: true,
          sortBy: ['isRefined']
        },
        Component: RefinementList,
        headerProps: {
          heading: 'Event Type',
          includedAttributes: 'eventType'
        }
      }
    ],
    [TAB_CATEGORY_ENUM.HOST_CREDIT_ENTITY]: [
      {
        componentProps: {
          attribute: 'accountManagerName',
          classNames: {
            ...classNameGroup,
            list: `${classNameGroup.list} mt-4`,
            searchableForm: 'searchkit-search-box-form',
            searchBox: 'searchkit-search-box searchkit-icon',
            selectedItem: 'searchkit-selected-item'
          },
          limit: DEFAULT_FILTER_OPTIONS_LIMIT,
          searchable: true,
          searchablePlaceholder: 'Search',
          showMore: true,
          sortBy: ['isRefined']
        },
        Component: RefinementList,
        headerProps: {
          heading: 'Account Manager',
          includedAttributes: 'accountManagerName'
        }
      }
    ]
  };
};
