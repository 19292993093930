import Image from 'next/image';
import { Fragment } from 'react';

import { ProductSearchBar } from '@/components/atomic/molecules';
import { Text, TextKeyValuePair } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const svgGroup = [
  'search-icon-animation-4',
  'search-icon-animation-3',
  'search-icon-animation-2',
  'search-icon-animation-1'
];

const SectionTitle = () => (
  <Fragment>
    <Text
      {...{
        content: 'Looking to plan a perfect event!',
        className:
          'flex pulse-button-hover justify-center px-6 py-1 self-center rounded-lg border border-cinnamon-satin text-cinnamon-satin text-xl bg-white'
      }}
    />
    <TextKeyValuePair
      {...{
        className: 'justify-center font-semibold text-4xl',
        label: `Let's Find The`,
        labelClass: 'px-1',
        spaceTop: '',
        value: 'RIGHT PRODUCT',
        valueClassName: 'text-cinnamon-satin'
      }}
    />
  </Fragment>
);

const AnimationImage = ({ svgName }) => (
  <Image
    {...{
      alt: svgName,
      height: 0,
      priority: true,
      src: `${staticMediaStoreBaseURL}/icons/${svgName}.svg`,
      style: { height: 182, width: 281 },
      width: 0
    }}
  />
);

const SearchBoxOrganism = ({
  searchableAttribute,
  setSearchableAttribute
}) => (
  <div className='flex flex-col gap-4 justify-center text-center relative z-10 pt-10'>
    <SectionTitle />
    <div className='flex flex-col justify-center relative'>
      <div className='relative mx-auto z-10 image-container w-72 h-48'>
        {svgGroup.map((svgName, index) => (
          <AnimationImage
            key={index}
            {...{ svgName }}
          />
        ))}
      </div>
      <ProductSearchBar
        {...{
          className:
            'flex mx-auto relative -top-5 w-[62rem] homepage-searchbox',
          searchableAttribute,
          setSearchableAttribute
        }}
      />
    </div>
  </div>
);

export default SearchBoxOrganism;
