import { Toaster } from '@/components/atomic/atoms';

// TODO: successToast -> toastType & use TOAST_TYPE when we have to support 2+ types - create dictionary, even for iconSrc
// const TOAST_TYPE = {
//   ERROR: 'ERROR',
//   SUCCESS: 'SUCCESS',
//   WARNING: 'WARNING'
// }
const ToastContainer = ({ setShowToast, showToast }) => {
  const { message, show, successToast } = showToast;
  return (
    show && (
      <div className='flex mx-auto gap-4'>
        <Toaster
          {...{
            iconSrc: successToast
              ? 'check-completed-green-icon.svg'
              : 'failed.svg',
            message,
            setMessage: setShowToast,
            showIcon: true
          }}
        />
      </div>
    )
  );
};

export default ToastContainer;
