import Image from 'next/image';
import { useRouter } from 'next/router';
import { useRef } from 'react';

import { NavigationMenu } from '@/components/atomic/atoms/';
import { Button, SelectFormInput, Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import { getPlannerName } from '@/services/identity.service';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';

const createUserEventIconPair = [
  {
    alt: 'user-event-white-icon',
    className: 'block user-event-brand-icon',
    icon: 'user-event-white-icon.svg'
  },
  {
    alt: 'user-event-brand-icon',
    className: 'hidden user-event-white-icon',
    icon: 'user-event-brand-icon.svg'
  }
];

const productSearchIconPair = [
  {
    alt: 'product-search-white-icon',
    className: ' block search-brand-icon',
    icon: 'search-white-icon-v1.svg'
  },
  {
    alt: 'product search brand icon',
    className: 'hidden search-white-icon',
    icon: 'search-brand-icon-v1.svg'
  }
];

const menuIconPair = [
  {
    alt: 'menu-white-icon',
    className: 'action-center-menu-white-icon'
  },
  {
    alt: 'menu-red-icon',
    className: 'hidden action-center-menu-red-icon'
  }
];

const ActionCenterNavigation = ({
  isMenuOpen,
  setIsMenuOpen,
  setOpenAddHostModal
}) => {
  const router = useRouter();
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useOnClickOutside(menuRef, () => setIsMenuOpen(false));

  const onClickNavigate = ({ pageName }) => {
    router.push(
      getPageURL({
        pageName
      })
    );
  };

  return (
    <div
      className='flex gap-4 items-center justify-between w-full md:w-auto mb-4 md:mb-0'
      ref={menuRef}
    >
      <Image
        {...{
          alt: 'logo',
          className: 'flex md:hidden',
          height: 0,
          onClick: toggleMenu,
          src: `${staticMediaStoreBaseURL}/icons/hafla-white-logo.svg`,
          style: { height: 24, width: 70 },
          width: 0
        }}
      />

      <Button
        {...{
          alt: 'navigate brand icon',
          className:
            'text-brand font-medium text-base bg-white rounded-lg px-4 items-center py-2 hidden md:block',
          iconHeight: 20,
          iconPosition: 'Right',
          iconURL: `${staticMediaStoreBaseURL}/icons/navigate-brand-icon-v2.svg`,
          iconWidth: 20,
          label: 'Quick Find',
          onClick: () =>
            router.push(
              getPageURL({
                pageName: PAGE_NAME.NAVIGATION.label
              })
            )
        }}
      />
      <div className='flex gap-6 cursor-pointer relative z-20 justify-end'>
        <div className='w-10 h-10'>
          <div className='inner user-event-icon hover:bg-white rounded w-10 h-10 relative'>
            {createUserEventIconPair.map(({ alt, className, icon }, id) => (
              <Image
                key={`${alt}_${id}`}
                {...{
                  alt,
                  className: `relative left-[7px] top-[7px] cursor-pointer relative left-[7px] top-[7px] cursor-pointer ${className}`,
                  height: 0,
                  onClick: () =>
                    onClickNavigate({
                      pageName:
                        PAGE_NAME.CREATE_USER_EVENT_UPSERT_HOST_PROFILE.label
                    }),
                  onTouchStart: () =>
                    onClickNavigate({
                      pageName:
                        PAGE_NAME.CREATE_USER_EVENT_UPSERT_HOST_PROFILE.label
                    }),
                  src: `${staticMediaStoreBaseURL}/icons/${icon}`,
                  style: { height: 26, width: 26 },
                  width: 0
                }}
              />
            ))}
          </div>
        </div>
        <div className='w-10 h-10 hidden md:block'>
          <div className='inner hover:bg-white rounded w-10 h-10 search-icon'>
            {productSearchIconPair.map(({ alt, className, icon }, id) => (
              <Image
                key={`${alt}_${id}`}
                {...{
                  alt,
                  className: `relative left-[7px] top-[7px] cursor-pointer ${className}`,
                  height: 0,
                  onClick: () =>
                    router.push(
                      getPageURL({
                        pageName: PAGE_NAME.PRODUCT_SEARCH.label
                      })
                    ),
                  src: `${staticMediaStoreBaseURL}/icons/${icon}`,
                  style: { height: 26, width: 26 },
                  width: 0
                }}
              />
            ))}
          </div>
        </div>
        <div className='relative action-center-welcome-menu rounded-lg w-10 h-10'>
          {menuIconPair.map(({ alt, className }, id) => (
            <Image
              key={`${alt}_${id}`}
              {...{
                alt: isMenuOpen ? 'close menu icon' : 'open menu icon',
                className: `relative left-[5px] top-[5px] cursor-pointer ${className}`,
                height: 0,
                onClick: toggleMenu,
                src: `${staticMediaStoreBaseURL}/icons/${isMenuOpen ? 'menu-close-white-icon.svg' : 'menu-white-icon-v1.svg'}`,
                style: { height: 30, width: 30 },
                width: 0
              }}
            />
          ))}
          {isMenuOpen && (
            <NavigationMenu
              {...{
                menuClass: 'top-[70px]',
                plannerName: getPlannerName(),
                router,
                setOpenAddHostModal,
                toggleMenu
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const ActionCenterHeader = ({
  clearErrors,
  getValues,
  isMenuOpen,
  isPlannerManager,
  opsUserList,
  register,
  setIsMenuOpen,
  setOpenAddHostModal,
  setValue
}) => {
  const delightTeamMembers = opsUserList.filter(
    ({ isDelightTeam }) => isDelightTeam
  );

  return (
    <div className='flex flex-col-reverse md:flex-row justify-between items-start'>
      <div className='flex flex-col'>
        <div className={`flex ${isPlannerManager ? 'gap-2' : 'gap-1'} `}>
          <Text
            {...{
              className: `text-lg md:text-2xl font-semibold text-white md:w-56 ${
                isPlannerManager ? 'w-[175px] mt-[8px]' : 'w-[172px] mb-2.5'
              }`,
              content: '🎉 Welcome Back,'
            }}
          />
          {isPlannerManager ? (
            <SelectFormInput
              {...{
                clearErrors,
                dbName: 'plannerId',
                getValues,
                placeholderForGradientBackground: true,
                register,
                selectContainerClassName:
                  'action-center-planner-dropdown z-[10]',
                setValue,
                values: delightTeamMembers
              }}
            />
          ) : (
            <Text
              {...{
                className:
                  'text-lg md:text-2xl font-semibold text-white truncate text-ellipsis w-fit md:w-auto',
                content: `${getPlannerName()}!`
              }}
            />
          )}
        </div>
        <Text
          {...{
            className: 'text-xs italic font-medium text-white',
            content: 'This is your Event Planning Dashboard'
          }}
        />
      </div>
      <ActionCenterNavigation
        {...{ isMenuOpen, setIsMenuOpen, setOpenAddHostModal }}
      />
    </div>
  );
};

export default ActionCenterHeader;
