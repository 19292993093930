import Image from 'next/image';
import { useState } from 'react';

import {
  ApplyForCreditBanner,
  HostCreditEntityCreditUtilization,
  HostEventStatistics,
  LetsPlanFirstEvent,
  RequestCreditModal
} from '@/components/atomic/atoms';
import ToastContainer from '@/components/atomic/molecules/common/ToastContainer';
import {
  Avatar,
  Button,
  Text,
  TextKeyValuePair,
  TextWithIcon,
  Tooltip
} from '@/components/atomic/nuclei';
import {
  DEFAULT_TOAST_CONFIG,
  HOST_INTERACTION_STATUS,
  staticMediaStoreBaseURL
} from '@/config/common';
import { CREDIT_REQUEST_STATUS } from '@/config/hostCreditEntity';
import {
  convertToCurrencyFormat,
  parseFormatPriceNumberValueFromAPI
} from '@/lib/numberStringUtils';
import { convertToShortMonthDateFormat } from '@/lib/time';
import { isEmptyOrNull, parseNumberedLabel } from '@/lib/utils';

const activeModalsConfig = {
  REQUEST_CREDIT: {
    component: RequestCreditModal,
    key: 'REQUEST_CREDIT',
    value: false
  },
  TOAST_CONTAINER: {
    component: ToastContainer,
    key: 'TOAST_CONTAINER',
    value: DEFAULT_TOAST_CONFIG
  }
};

const HostNameWithVerifyIcon = ({ name, isMobileVerified }) => {
  const tooltipConfigListForVerifiedMobileHost = [
    {
      target: 'mobile-verified',
      text: 'Mobile Number Verified'
    }
  ];
  const tooltipConfigListForUnverifiedMobileHost = [
    {
      target: 'mobile-not-verified',
      text: 'Mobile Number Not Verified'
    }
  ];

  const verifiedHostParameters = {
    icon: 'host-verified-icon.svg',
    imgAlt: 'host verified',
    imgId: 'mobile-verified',
    tooltipConfigList: tooltipConfigListForVerifiedMobileHost
  };

  const unverifiedHostParameters = {
    icon: 'host-not-verified-icon.svg',
    imgAlt: 'host not verified',
    imgId: 'mobile-not-verified',
    tooltipConfigList: tooltipConfigListForUnverifiedMobileHost
  };

  const { icon, imgAlt, imgId, tooltipConfigList } = isMobileVerified
    ? verifiedHostParameters
    : unverifiedHostParameters;
  return (
    <div className='flex gap-2'>
      <Text
        {...{
          className:
            'text-nero text-xl font-semibold text-white max-w-[16rem] truncate',
          content: name
        }}
      />
      <Image
        alt={imgAlt}
        className='relative -top-2'
        height={0}
        id={imgId}
        priority={true}
        src={`${staticMediaStoreBaseURL}/icons/${icon}`}
        style={{
          height: 20,
          width: 20
        }}
        width={0}
      />
      <Tooltip {...{ configList: tooltipConfigList }} />
    </div>
  );
};

const HostProfileSidebar = ({
  hostCreditEntityCreditRequests,
  hostProfile: {
    hostCreditEntityStatistics: { creditFacilityInformation = {} } = {},
    hostEventStatistics = {},
    user = {}
  } = {},
  setHostCreditEntityCreditRequests
}) => {
  const {
    createdAt,
    hostCreditEntity,
    hostNumber,
    interactionStatus,
    isMobileVerified,
    name,
    updatedAt
  } = user;
  const { name: hostCreditEntityName = null, id: hostCreditEntityId = null } =
    hostCreditEntity ?? {};

  const showLetsPlanFirstEvent =
    interactionStatus === HOST_INTERACTION_STATUS.NEW.value;

  const isApprovedOrRequestedCreditRequestExist =
    hostCreditEntityCreditRequests?.some(({ status }) =>
      [
        CREDIT_REQUEST_STATUS.APPROVED.value,
        CREDIT_REQUEST_STATUS.REQUESTED.value
      ].includes(status)
    );

  const isApprovedCreditRequestExist = hostCreditEntityCreditRequests?.some(
    ({ status }) => [CREDIT_REQUEST_STATUS.APPROVED.value].includes(status)
  );

  const [latestCreditRequest] = hostCreditEntityCreditRequests || [];

  const { requestedCreditLimit, requester, status } =
    latestCreditRequest || {};

  const showLatestCreditRequestSummary =
    !isEmptyOrNull(latestCreditRequest) &&
    [
      CREDIT_REQUEST_STATUS.REQUESTED.value,
      CREDIT_REQUEST_STATUS.REJECTED.value
    ].includes(status);

  const applyCreditCTALabel = isApprovedOrRequestedCreditRequestExist
    ? 'Apply For New Credit'
    : 'Apply For Credit';

  const [activeModal, setActiveModal] = useState(null);

  const ActiveModal = () => {
    let ActiveModalJSX = () => <></>;
    let activeModalProps = {};

    if (activeModal) {
      const { key: activeModalKey, value: activeModalValue } = activeModal;
      switch (activeModalKey) {
        case activeModalsConfig.REQUEST_CREDIT.key:
          ActiveModalJSX = activeModalsConfig.REQUEST_CREDIT.component;
          activeModalProps = {
            creditFacilityInformation,
            hostCreditEntityId,
            requestCreditModalToggle: () => setActiveModal(null),
            setHostCreditEntityCreditRequests,
            setShowToastContainer: (requestToUpdate) =>
              setActiveModal({
                key: activeModalsConfig.TOAST_CONTAINER.key,
                value: requestToUpdate
              })
          };
          break;
        case activeModalsConfig.TOAST_CONTAINER.key:
          ActiveModalJSX = activeModalsConfig.TOAST_CONTAINER.component;
          activeModalProps = {
            setShowToast: (requestToUpdate) =>
              setActiveModal({
                key: activeModalsConfig.TOAST_CONTAINER.key,
                value: requestToUpdate
              }),
            showToast: activeModalValue
          };
          break;
        default:
          break;
      }
    }

    return <ActiveModalJSX {...{ ...activeModalProps }} />;
  };

  return (
    <div
      className={`md:w-108 fixed h-screen z-30 overflow-auto bg-host-event-sidebar bg-no-repeat bg-cover px-6 py-4 md:pl-10 md:pr-10 md:pt-5 flex flex-col gap-3 scroll`}
    >
      <Image
        alt='hafla-logo'
        className='rounded-lg'
        height={0}
        priority={true}
        src={`${staticMediaStoreBaseURL}/icons/hafla-white-logo.svg`}
        style={{
          height: 40,
          width: 125
        }}
        width={0}
      />
      <div className='flex items-center gap-4 mt-4'>
        <div className='w-[100px] h-[100px] rounded-lg'>
          <Avatar
            {...{
              badgeStyle: 'bg-white',
              name,
              textStyle: 'text-brand-gradient'
            }}
          />
        </div>
        <div className='flex flex-col gap-1'>
          <HostNameWithVerifyIcon {...{ name, isMobileVerified }} />
          {hostCreditEntityName && (
            <Text
              {...{
                className:
                  'text-base text-white font-medium max-w-[18rem] truncate',
                content: hostCreditEntityName
              }}
            />
          )}
        </div>
      </div>
      <div className='flex items-center justify-between relative'>
        <Text
          {...{
            className: 'text-xs text-white font-medium',
            content: `Last Updated ${convertToShortMonthDateFormat(updatedAt)}`
          }}
        />
        <div className='flex gap-2'>
          <Text
            {...{
              className: `${HOST_INTERACTION_STATUS[interactionStatus]?.className} rounded-lg px-2 py-1 text-sm font-medium self-center items-center flex`,
              content: HOST_INTERACTION_STATUS[interactionStatus]?.label
            }}
          />
          <TextWithIcon
            {...{
              alt: 'host number icon',
              className:
                'bg-white border-neutral border rounded-lg px-3 py-1 self-center items-center flex',
              icon: 'host-number-brand-v1-icon.svg',
              id: 'host-number',
              label: parseNumberedLabel(hostNumber),
              labelStyle: 'text-sm font-medium text-dim-gray',
              onHoverCopy: true,
              tooltipText: 'Host Number'
            }}
          />
        </div>
      </div>
      <div className='border-t border-opacity-color mt-2 mb-4'></div>
      <div className='flex flex-col justify-center items-center mt-auto w-[26rem]'>
        {showLetsPlanFirstEvent ? (
          <LetsPlanFirstEvent
            {...{
              createdAt,
              labelClassName: 'text-white'
            }}
          />
        ) : (
          <HostEventStatistics {...{ createdAt, hostEventStatistics }} />
        )}
        {!isEmptyOrNull(hostCreditEntityId) &&
          (isApprovedCreditRequestExist ? (
            <HostCreditEntityCreditUtilization
              {...{
                creditFacilityInformation,
                creditUtilizationStyle: 'bg-white-opacity'
              }}
            />
          ) : (
            <ApplyForCreditBanner
              {...{
                labelStyle: 'font-semibold text-white',
                valueStyle: 'font-medium text-white text-sm'
              }}
            />
          ))}
        <div className='flex flex-col gap-2 w-full text-white'>
          {!isEmptyOrNull(hostCreditEntityId) && (
            <Button
              {...{
                className: 'border border-white rounded-lg py-3 font-medium',
                label: applyCreditCTALabel,
                onClick: () =>
                  setActiveModal({
                    key: activeModalsConfig.REQUEST_CREDIT.key,
                    value: true
                  })
              }}
            />
          )}
          {showLatestCreditRequestSummary && (
            <div className='flex gap-1 font-medium'>
              <TextKeyValuePair
                {...{
                  className: 'flex-row !gap-1',
                  label: `${requester.name}'s`,
                  labelClass: 'text-xs font-semibold',
                  value: 'request for',
                  valueClassName: 'text-xs'
                }}
              />
              <TextKeyValuePair
                {...{
                  className: 'flex-row !gap-1',
                  label: convertToCurrencyFormat({
                    value: parseFormatPriceNumberValueFromAPI(
                      requestedCreditLimit
                    )
                  }),
                  labelClass: 'text-xs font-semibold',
                  value:
                    status === CREDIT_REQUEST_STATUS.REJECTED.value
                      ? 'has been rejected.'
                      : 'is under review.',
                  valueClassName: 'text-xs'
                }}
              />
            </div>
          )}
        </div>
      </div>
      <ActiveModal />
    </div>
  );
};

export default HostProfileSidebar;
