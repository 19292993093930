import { useState } from 'react';

import { PageNotFound, SwipeableCardSlider } from '@/components/atomic/atoms';
import { NewAssignedUserEventCard } from '@/components/atomic/molecules';
import {
  Text,
  TextKeyValuePair,
  TextWithIcon
} from '@/components/atomic/nuclei';
import { DEVICE_SURFACE } from '@/config/common';
import useSwipeHandlers from '@/hooks/useSwipeHandlers';
import { GetUiDeviceSurface } from '@/lib/screenResolution';
import { isEmptyOrNull } from '@/lib/utils';
import { getQueryParameterForScopedUserEvents } from '@/services/actionCenter.service';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';

const openNewAssignedUserEvents = ({ selectedPlannerId }) =>
  window.open(
    getPageURL({
      pageName: PAGE_NAME.NEW_ASSIGNED_USER_EVENTS.label,
      queryParams: getQueryParameterForScopedUserEvents({
        selectedPlannerId
      })
    }),
    '_blank'
  );

const ViewAllCTA = ({
  className = 'hidden',
  labelStyle = 'text-sm text-dim-gray font-medium',
  onClick = () => {}
}) => (
  <TextWithIcon
    {...{
      className,
      iconSuffix: {
        alt: 'arrow icon',
        icon: 'angel-right-icon.svg',
        iconHeight: 12,
        iconStyle: '',
        iconWidth: 12
      },
      label: 'View All',
      labelStyle,
      onClick
    }}
  />
);
const NewAssignedUserEventBodyWeb = ({ events, selectedPlannerId }) => (
  <div className='md:flex gap-4 overflow-x-auto no-scrollbar'>
    {events.length > 0 ? (
      events.map((upcomingUserEventDetail, index) => {
        const queryParamsForScopedUserEvents = {
          userEventId: upcomingUserEventDetail.userEventId,
          ...getQueryParameterForScopedUserEvents({
            selectedPlannerId
          })
        };

        const showOverlay = index === 9;
        return (
          <div
            key={index}
            className='relative'
          >
            <NewAssignedUserEventCard
              key={index}
              {...{
                onClickCard: ({ action = '' }) =>
                  window.open(
                    getPageURL({
                      pageName: PAGE_NAME.NEW_ASSIGNED_USER_EVENTS.label,
                      queryParams: !isEmptyOrNull(action)
                        ? { action, ...queryParamsForScopedUserEvents }
                        : queryParamsForScopedUserEvents
                    }),
                    '_blank'
                  ),
                showOverlay,
                source: PAGE_NAME.ACTION_CENTER.label,
                upcomingUserEventDetail
              }}
            />
            {showOverlay && (
              <div className='absolute inset-0 bg-black bg-opacity-50 rounded-lg flex items-center justify-center'>
                <Text
                  {...{
                    className: 'font-semibold text-white cursor-pointer',
                    content: 'View All',
                    onClick: () =>
                      openNewAssignedUserEvents({
                        selectedPlannerId
                      })
                  }}
                />
              </div>
            )}
          </div>
        );
      })
    ) : (
      <PageNotFound
        {...{
          className: 'pt-4 mx-auto',
          iconHeight: 114,
          iconWidth: 120,
          notFoundImage: 'dashboard-event-not-found.svg',
          title: `Awesome! You're all caught up`,
          titleStyle: 'font-light text-dim-gray mt-2'
        }}
      />
    )}
  </div>
);

const NewAssignedUserEventBodyMobile = ({ events, selectedPlannerId }) => {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const eventCount = events.length;
  const swipeHandlers = useSwipeHandlers({
    currentCardIndex,
    eventCount,
    setCurrentCardIndex
  });

  return (
    <div {...swipeHandlers}>
      {eventCount > 0 ? (
        <div
          className='flex transition-transform duration-300'
          style={{
            // TODO: HP/FV try not to use inline styles
            transform: `translateX(-${currentCardIndex * 100}%)`
          }}
        >
          {events.map((upcomingUserEventDetail, index) => {
            const queryParamsForScopedUserEvents = {
              userEventId: upcomingUserEventDetail.userEventId,
              ...getQueryParameterForScopedUserEvents({
                selectedPlannerId
              })
            };
            const showOverlay = index === 9;
            return (
              <div
                key={index}
                className='min-w-full card-flex-shrink relative'
              >
                <NewAssignedUserEventCard
                  key={index}
                  {...{
                    onClickCard: ({ action = '' }) =>
                      window.open(
                        getPageURL({
                          pageName: PAGE_NAME.NEW_ASSIGNED_USER_EVENTS.label,
                          queryParams: !isEmptyOrNull(action)
                            ? { action, ...queryParamsForScopedUserEvents }
                            : queryParamsForScopedUserEvents
                        }),
                        '_blank'
                      ),
                    showOverlay,
                    source: PAGE_NAME.ACTION_CENTER.label,
                    upcomingUserEventDetail
                  }}
                />
                {showOverlay && (
                  <div className='absolute inset-0 bg-black bg-opacity-50 rounded-lg flex items-center justify-center'>
                    <Text
                      {...{
                        className: 'font-semibold text-white cursor-pointer',
                        content: 'View All',
                        onClick: () =>
                          openNewAssignedUserEvents({
                            selectedPlannerId
                          }),
                        showOverlay
                      }}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <PageNotFound
          {...{
            className: 'pt-4 mx-auto',
            iconHeight: 114,
            iconWidth: 120,
            notFoundImage: 'dashboard-event-not-found.svg',
            title: `Awesome! You're all caught up`,
            titleStyle: 'font-light text-dim-gray mt-2'
          }}
        />
      )}
      {eventCount > 0 && (
        <SwipeableCardSlider
          {...{
            cardCount: eventCount,
            currentCardIndex,
            onClickViewAllCTA: () =>
              openNewAssignedUserEvents({ selectedPlannerId }),
            setCurrentCardIndex
          }}
        />
      )}
    </div>
  );
};

const NewAssignedUserEventHeader = ({
  selectedPlannerId,
  upcomingAssignedUserEventsInfo
}) => (
  <div className='flex justify-between items-center'>
    <div className='flex flex-col'>
      <TextKeyValuePair
        {...{
          className: 'flex-row items-center',
          label: 'New Events Assigned',
          labelClass: 'text-xl md:text-3xl font-semibold text-dim-gray pb-1',
          spaceTop: '',
          value:
            upcomingAssignedUserEventsInfo.upcomingAssignedUserEventsCount,
          valueClassName:
            'bg-brand-gradient text-white rounded-full min-w-6 h-6 items-center text-xs md:text-sm font-medium px-1 justify-center flex'
        }}
      />
      <Text
        {...{
          className: 'text-silver font-semibold text-xs md:text-base',
          content: `Let's Get These Events Moving!`
        }}
      />
    </div>
    {upcomingAssignedUserEventsInfo.upcomingAssignedUserEventsCount > 0 && (
      <ViewAllCTA
        {...{
          className:
            'cursor-pointer shadow-card py-1 px-2 rounded mt-7 hidden md:flex',
          onClick: () => openNewAssignedUserEvents({ selectedPlannerId })
        }}
      />
    )}
  </div>
);

const NewAssignedUserEventOrganism = ({
  selectedPlannerId,
  upcomingAssignedUserEventsInfo
}) => {
  const surface = GetUiDeviceSurface();

  const events =
    upcomingAssignedUserEventsInfo.upcomingAssignedUserEvents || [];

  const NewAssignedUserEventBodyMap = {
    [DEVICE_SURFACE.MOBILE]: NewAssignedUserEventBodyMobile,
    [DEVICE_SURFACE.WEB]: NewAssignedUserEventBodyWeb
  };

  const NewAssignedUserEventBody = NewAssignedUserEventBodyMap[surface];

  return (
    <div className='flex flex-col gap-1 flex-1 bg-white shadow-navigate-cart rounded-lg navigate-cart p-3 overflow-hidden h-52 md:h-auto'>
      <NewAssignedUserEventHeader
        {...{
          selectedPlannerId,
          upcomingAssignedUserEventsInfo
        }}
      />
      <div className='flex flex-col gap-4 md:mt-4 mt-2 relative overflow-hidden'>
        <NewAssignedUserEventBody
          {...{
            events,
            selectedPlannerId
          }}
        />
      </div>
    </div>
  );
};

export default NewAssignedUserEventOrganism;
