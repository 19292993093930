import { useRouter } from 'next/router';

import {
  EPICBrief,
  EventCartListSideBarBody,
  EventCartListSideBarHead
} from '@/components/atomic/atoms';
import { Button } from '@/components/atomic/nuclei';
import { ALLOCATION_STATUS } from '@/config/common';
import { useIsMobile } from '@/lib/screenResolution';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';

const EventCartListSidebar = ({
  activeModalsConfig,
  setActiveModal,
  setShowToast,
  userEvent
}) => {
  const { userEventDetails: [{ allocationStatus, userEventId } = {}] = [] } =
    userEvent || {};
  const router = useRouter();

  const [isMobile] = useIsMobile();

  const displayHostEventProfile = () => {
    router.push(
      getPageURL({
        excludeBaseUrl: false,
        pageName: PAGE_NAME.HOST_EVENT_PROFILE.label,
        pathParams: {
          userEventId
        }
      })
    );
  };

  return (
    <div className='md:w-108 bg-white px-6 py-4 md:pl-10 md:pr-10 pt-20 md:pt-5 flex flex-col gap-3'>
      <EventCartListSideBarHead
        {...{
          displayHostEventProfile,
          isMobile,
          userEvent
        }}
      />
      <EventCartListSideBarBody
        {...{
          router,
          userEvent
        }}
      />
      <div className='mt-2 mb-4 border-t border-gray'></div>
      <EPICBrief
        {...{
          setShowToast,
          userEvent
        }}
      />
      <Button
        {...{
          className:
            'border border-brand rounded-lg py-3 text-brand font-medium',
          disabled:
            allocationStatus === ALLOCATION_STATUS.REASSIGN_REQUESTED.value,
          label: 'Request Reassign',
          onClick: () =>
            setActiveModal({
              key: activeModalsConfig.NEW_ASSIGNED_USER_EVENT_PREVIEW.key,
              value: userEvent
            })
        }}
      />
    </div>
  );
};

export default EventCartListSidebar;
