import {
  FilterHeading,
  SearchkitFilterHeader
} from '@/components/atomic/atoms';
import {
  RECORDS_OPTIONS,
  TAB_CATEGORY_ATTRIBUTE
} from '@/config/searchkit/navigation';

const DynamicFilter = ({ Component, componentProps, headerProps }) => (
  <div className='mb-4'>
    <FilterHeading {...headerProps} />
    <Component {...componentProps} />
  </div>
);

const NavigationFilters = ({
  clearBookingDateFilter,
  clearCreatedAtDateFilter,
  clearEventDateFilter,
  dynamicFilterProps,
  resultCountLabel,
  setClearBookingDateFilter,
  setClearCreatedAtDateFilter,
  setClearEventDateFilter,
  setSelectedOption,
  setShowFilters,
  showFilters
}) => {
  const showCountLabel = !showFilters && resultCountLabel;
  return (
    <div
      className={`flex flex-col fixed bg-white top-36 filter-scroll overflow-x-hidden overflow-y-auto searchkit-filter-container z-[1] ${showCountLabel ? 'w-[100%] h-[7vh]' : 'w-[18.5rem] h-[80vh]'}`}
    >
      <SearchkitFilterHeader
        {...{
          excludedAttributes: [TAB_CATEGORY_ATTRIBUTE, 'query'],
          onClickClearAll: () => {
            setClearBookingDateFilter(!clearBookingDateFilter);
            setClearCreatedAtDateFilter(!clearCreatedAtDateFilter);
            setClearEventDateFilter(!clearEventDateFilter);
            setSelectedOption(RECORDS_OPTIONS.ALL_RECORDS);
          },
          resultCountLabel,
          setShowFilters,
          showFilters
        }}
      />
      <div className={`${showFilters ? 'visible z-10' : 'hidden'}`}>
        <div className='w-[18rem] hidden md:block mb-10'>
          <div className='searchkit-filter-container pl-5'>
            {dynamicFilterProps.map(
              (
                { Component, componentProps, headerProps, showFilter = true },
                index
              ) => (
                <div
                  className={showFilter ? '' : 'hidden'}
                  key={`${componentProps.attribute}_${index}`}
                >
                  <DynamicFilter
                    {...{
                      Component,
                      componentProps,
                      headerProps,
                      showFilter
                    }}
                  />
                  <div className='border-t border-platinum mt-3 mb-4'></div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavigationFilters;
